<template>
  <div class="mt-1" :class="depth === 0 ? 'comment-item' : ''">
    <div
      v-if="comment.from"
      class="d-flex flex-row align-items-start justify-content-start w-100"
    >
      <b-avatar
        v-if="comment.from && G_CURRENT_FB_PAGE.id !== comment.from.id"
        button
        size="2.5rem"
        class="header-avatar mr-1"
        :src="comment.from.picture"
      ></b-avatar>
      <b-avatar
        v-else-if="G_CURRENT_FB_PAGE.id === comment.from.id"
        button
        size="2.5rem"
        class="header-avatar mr-1"
        :src="G_CURRENT_FB_PAGE.picture"
      ></b-avatar>

      <b-avatar
        v-else
        button
        size="2.5rem"
        class="header-avatar mr-1"
      ></b-avatar>

      <div class="d-flex flex-column w-100">
        <div class="comment-text" v-if="!comment.attachment">
          <div
            v-if="G_CURRENT_FB_PAGE.id === comment.from.id"
            class="comment-author"
          >
            <span> <feather-icon icon="UserIcon" size="15" /> Author</span>
          </div>
          <span v-if="comment.from"> {{ comment.from.name }} </span>
          <change-sms
            v-if="comment.message"
            :sms-data="comment.message"
            :text-length="250"
            type-show-more="span"
          />
        </div>

        <div class="comment-image" v-if="comment.attachment">
          <span v-if="comment.from"> {{ comment.from.name }} </span>
          <b-img
            class="fb-comment-image"
            v-if="comment.attachment"
            :src="comment.attachment.url"
            fluid
          ></b-img>
        </div>

        <div class="comment-stats">
          <span> {{ comment.created_time_formatted }}</span>
          <template v-if="comment.display_replies != null">
            <span
              v-if="!comment.display_replies"
              class="view-reply-text"
              @click="comment.display_replies = !comment.display_replies"
            >
              View replies ({{ comment.comments.length }})</span
            >
            <span
              v-else
              class="view-reply-text"
              @click="comment.display_replies = !comment.display_replies"
            >
              Hide replies</span
            >
          </template>

          <span class="reply-button" @click="openInputComment(comment)"
            >Reply</span
          >
        </div>

        <transition
          name="fade"
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave"
        >
          <div
            v-if="comment.show_input_reply"
            class="d-flex align-items-start justify-content-start mt-1"
          >
            <b-avatar
              button
              size="2.5rem"
              class="header-avatar mr-1"
              :src="G_CURRENT_FB_PAGE.picture"
            ></b-avatar>

            <b-form-textarea
              :key="comment.id"
              class="message-box"
              v-model="comment.reply"
              :placeholder="'Reply to ' + comment.from.name"
              :disabled="sending"
              :maxlength="maxLength"
              rows="3"
              max-rows="8000"
              @keydown.enter="storeComment(comment)"
              @keydown.enter.shift.exact.prevent="comment.reply += '\n'"
            />
          </div>
        </transition>

        <div
          v-if="comment.display_replies === true && depth === 0"
          class="comment-reply reply-margin"
        >
          <transition name="fade">
            <div v-for="(reply, index) in comment.comments" :key="index">
              <RecursiveComment
                :comment="reply"
                :firstParent="firstParent"
                :depth="depth + 1"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div
      v-if="comment.display_replies === true && depth !== 0"
      class="comment-reply ml-1"
    >
      <div v-for="(reply, index) in comment.comments" :key="index">
        <transition name="fade">
          <RecursiveComment
            :comment="reply"
            :firstParent="firstParent"
            :depth="depth + 1"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
import MetaService from "@/views/commons/Messenger/components/services/messenger.meta.service.js";

export default {
  name: "RecursiveComment",
  components: {
    ChangeSms,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    firstParent: Object,
    depth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sending: false,
      maxLength: 8000,
      currentReply: null,
      message: "",
      showInputReply: false,
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_FB_COMMENT_CHAT: "MessengerMetaStore/G_CURRENT_FB_COMMENT_CHAT",
      G_CURRENT_FB_COMMENT_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_FB_COMMENT_SIDEBAR",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
    }),
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      el.offsetHeight;
      el.style.transition = "opacity 0.5s";
      el.style.opacity = 1;
      done();
    },
    leave(el, done) {
      el.style.transition = "opacity 0.5s";
      el.style.opacity = 0;
      done();
    },
    openInputComment(comment) {
      comment.show_input_reply = !comment.show_input_reply;
    },
    async storeComment(comment) {
      try {
        this.sending = true;
        let params = {
          message: comment.reply,
          page_id: this.G_CURRENT_FB_PAGE.id,
          comment,
          post_id: this.G_CURRENT_FB_COMMENT_SIDEBAR.id,
          main_comment_id: this.firstParent.id,
        };
        await MetaService.storeFacebookComment(params);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
<style  lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.comment-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;

  .heart-icon {
    cursor: pointer;

    &:hover {
      color: #ff0000;
    }
  }
  .comment-author {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;

    span {
      font-size: 0.9rem;
      color: #8e8e8e;
    }
  }

  .comment-text {
    width: fit-content;
    display: flex;
    flex-direction: column;
    background: #efefef;
    padding: 1rem;
    border-radius: 20px;

    span:nth-of-type(1) {
      font-weight: 900;
      margin-right: 1rem;
    }
  }

  .comment-image {
    width: fit-content;
    display: flex;
    flex-direction: column;

    span:nth-of-type(1) {
      font-weight: 900;
      margin-right: 1rem;
    }

    .fb-comment-image {
      margin-top: 1rem;
      width: 100%;
      max-width: 150px;
      height: auto;
      border-radius: 20px;
    }

    .fb-comment-image-reply {
      margin-top: 1rem;
      width: 100%;
      max-width: 80px;
      height: auto;
    }
  }
  .comment-stats {
    color: #8e8e8e;
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    justify-content: start;

    span:not(:last-child) {
      margin-right: 1rem;
    }

    .view-reply-text {
      user-select: none;
      color: #8e8e8e;
      cursor: pointer;
      font-size: 0.9rem;
    }

    .reply-button {
      user-select: none;
      color: #8f8f8f;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 900;
    }
  }

  .message-box {
    display: inline-block !important;
    max-width: -webkit-fill-available;
    width: 70%;
    padding: 0.7rem 1rem 0.7rem 1rem !important;
    border-radius: 15px;
    overflow: hidden;
    resize: none;
  }

  .reply-margin {
    margin: 0rem 0rem 0 3rem;
  }

  .comment-reply {
    .line {
      cursor: pointer;
      min-height: 1px;
      border-bottom: 1px solid #8e8e8e;
    }
    span:nth-of-type(2) {
      font-weight: 900;
    }
  }
}
.dark-layout {
  .comment-item {
    .comment-text {
      background: #111113;
    }
  }
}
</style>
