<template>
  <div class="">
    <svg :fill="'currentColor'" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      viewBox="0 0 512 512" xml:space="preserve">
    <g>
      <g>
        <path d="M500.599,157.901L435.2,114.304V0H76.8v114.304l-65.399,43.597C4.275,162.645,0,170.641,0,179.2v307.2
          C0,500.54,11.46,512,25.6,512h460.8c14.14,0,25.6-11.46,25.6-25.6V179.2C512,170.641,507.725,162.645,500.599,157.901z
          M435.2,145.067l39.595,26.394L435.2,197.086V145.067z M102.4,25.6h307.2v188.049L256,313.037l-153.6-99.388V25.6z M76.8,145.067
          v52.019l-39.595-25.617L76.8,145.067z M486.4,486.4H25.6V194.449l206.839,133.837L44.254,450.048
          c-5.939,3.849-7.637,11.776-3.797,17.698c2.441,3.806,6.554,5.854,10.752,5.854c2.389,0,4.787-0.674,6.938-2.048L256,343.526
          l197.854,128.026c2.15,1.374,4.548,2.048,6.938,2.048c4.198,0,8.311-2.048,10.761-5.854c3.84-5.922,2.133-13.85-3.797-17.698
          L279.569,328.277L486.4,194.449V486.4z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M256,51.2c-56.465,0-102.4,45.935-102.4,102.4c0,56.465,45.935,102.4,102.4,102.4s102.4-45.935,102.4-102.4
          C358.4,97.135,312.465,51.2,256,51.2z M179.2,153.6c0-42.419,34.381-76.8,76.8-76.8c16.58,0,31.838,5.376,44.39,14.31
          L193.51,197.99C184.576,185.438,179.2,170.18,179.2,153.6z M256,230.4c-16.58,0-31.838-5.376-44.39-14.31l106.88-106.88
          c8.934,12.553,14.31,27.81,14.31,44.39C332.8,196.019,298.419,230.4,256,230.4z"/>
      </g>
    </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>

<style lang="scss" scoped> 
</style>