<template>
  <div class="message-user-profile">
    <b-avatar
      size="55"
      variant="light-secondary"
      :src="
        G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.sender.imageUrl
      "
    />
    <strong>
      {{ G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.sender.name }}
    </strong>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "UserProfile",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR",
    }),
  },
};
</script>
<style lang="scss">
.message-user-profile {
  display: flex;
  gap: 1rem;
  align-items: center;
  strong {
    font-size: 1.2rem;
  }
}
</style>