<template>
  <div
    v-if="G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR"
    class="ig-current-chat-container"
  >
    <MessageHeader />
    <MessageContent />
    <MessageBox />
  </div>
  <div class="current-chat-messenger no-selected-chat" v-else>
    <p>Select a chat to start messaging</p>
  </div>
</template> 
<script>
import { mapGetters } from "vuex";
import MessageHeader from "@/views/commons/Messenger/components/instagram/messages/MessageHeader.vue";
import MessageContent from "@/views/commons/Messenger/components/instagram/messages/MessageContent.vue";
import MessageBox from "@/views/commons/Messenger/components/instagram/messages/MessageBox.vue";

export default {
  name: "InstagramCurrentChat",
  components: {
    MessageHeader,
    MessageContent,
    MessageBox,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR",
    }),
  },
};
</script>

<style lang="scss" scoped>
.ig-current-chat-container {
  border-left: 1px solid #eeeeee;
  background-color: #ffffff;
  height: 100%;
}
.ig-current-chat-container.no-selected-chat {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-layout {
  .ig-current-chat-container {
    background: #111113;
    border-left: 1px solid #313131;
  }
}
</style>