<template>
  <div
    v-if="G_CURRENT_FB_COMMENT_SIDEBAR === null"
    class="fb-current-chat-container d-flex justify-content-center align-items-center"
  >
    No chat selected.
  </div>

  <div class="fb-current-chat-container" v-else>
    <div class="header">
      <HeaderSkeleton
        v-if="
          G_CURRENT_FB_COMMENT_SIDEBAR === null ||
          G_CURRENT_FB_COMMENT_CHAT === null
        "
      />

      <b-row v-else>
        <b-col
          cols="12"
          md="7"
          lg="7"
          xl="7"
          xxl="7"
          class="d-flex justify-content-start align-items-center p-1"
        >
          <b-avatar
            button
            size="45"
            class="header-avatar"
            :src="
              G_CURRENT_FB_COMMENT_SIDEBAR.full_picture != null
                ? G_CURRENT_FB_COMMENT_SIDEBAR.full_picture
                : G_CURRENT_FB_PAGE.picture
            "
          ></b-avatar>
          <div class="header-info">
            <span
              class="header-text-bold"
              v-if="G_CURRENT_FB_COMMENT_SIDEBAR.message"
              >{{ G_CURRENT_FB_COMMENT_SIDEBAR.message | limitChars(50) }}</span
            >
            <span
              class="header-text-bold"
              v-else-if="G_CURRENT_FB_COMMENT_SIDEBAR.story"
              >{{ G_CURRENT_FB_COMMENT_SIDEBAR.story | limitChars(50) }}</span
            >
            <span class="header-text-bold" v-else>{{
              G_CURRENT_FB_PAGE.name + " post"
            }}</span>

            <div class="header-stats">
              <span
                >{{
                  G_CURRENT_FB_COMMENT_SIDEBAR.comments_count
                }}
                comments</span
              >
              <span class="hyphen-spacing">-</span>
              <span>{{
                G_CURRENT_FB_COMMENT_SIDEBAR.created_time_formatted
              }}</span>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="5"
          lg="5"
          xl="5"
          xxl="5"
          class="d-flex justify-content-end align-items-center p-1"
        >
          <b-button variant="outline-secondary" size="sm" class="mr-1">
            <feather-icon
              icon="AlertOctagonIcon"
              v-b-tooltip.hover="'Mark as spam'"
            ></feather-icon
          ></b-button>
          <b-button variant="outline-secondary" size="sm" class="mr-1">
            <feather-icon
              icon="MailIcon"
              v-b-tooltip.hover="'Mark as unread'"
            ></feather-icon>
          </b-button>
          <b-button variant="outline-secondary" size="sm" class="mr-1">
            <feather-icon
              icon="MailIcon"
              v-b-tooltip.hover="'Move to inbox'"
            ></feather-icon>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <Spinner
      v-if="
        G_CURRENT_FB_COMMENT_CHAT === null ||
        G_CURRENT_FB_COMMENT_SIDEBAR === null
      "
      :centerHorizontal="true"
      :centerVertical="true"
    />

    <perfect-scrollbar
      v-else
      class="custom-messages-scrollbar"
      ref="scrollContainer"
      @scroll="handleScroll"
    >
      <b-row class="post" no-gutters>
        <b-col cols="8" class="d-flex flex-column post-content" :offset="2">
          <div class="d-flex justify-content-start align-items-center">
            <b-avatar
              v-if="G_CURRENT_FB_PAGE.picture"
              size="45"
              variant="light-secondary"
              class="header-avatar ml-1 mt-1"
              :src="G_CURRENT_FB_PAGE.picture"
            >
            </b-avatar>
            <div
              class="d-flex flex-column align-items-start justify-content-start post-header"
            >
              <span v-if="G_CURRENT_FB_COMMENT_SIDEBAR.story">
                {{ G_CURRENT_FB_COMMENT_SIDEBAR.story }}
              </span>

              <span v-else>
                {{ G_CURRENT_FB_PAGE.name }}
              </span>

              <span v-if="G_CURRENT_FB_COMMENT_SIDEBAR.admin_creator">
                Published by
                {{ G_CURRENT_FB_COMMENT_SIDEBAR.admin_creator.name }} -
                {{ G_CURRENT_FB_COMMENT_SIDEBAR.created_time_formatted }}
              </span>
            </div>
          </div>

          <span class="post-message">
            {{ G_CURRENT_FB_COMMENT_SIDEBAR.message }}
          </span>

          <div
            class="attachments-container d-flex justify-content-center align-items-center"
            v-if="G_CURRENT_FB_COMMENT_SIDEBAR.attachments"
          >
            <div
              :key="index"
              v-for="(
                attachment, index
              ) in G_CURRENT_FB_COMMENT_SIDEBAR.attachments"
            >
              <b-img
                v-if="attachment.media_type === 'photo'"
                :rounded="isChangeOfProfilePicture ? 'circle' : '0'"
                class="fb-post-image"
                :src="attachment.url"
                fluid
              />

              <video
                v-else-if="attachment.media_type === 'video'"
                controls
                :src="attachment.url"
                class="fb-post-video"
              ></video>
            </div>
          </div>

          <div
            class="count-comments cursor-pointer"
            @click="showAllComments = !showAllComments"
          >
            <span>{{ G_CURRENT_FB_COMMENT_SIDEBAR.comments_count }}</span>
            <feather-icon icon="MessageSquareIcon" size="20" />
          </div>

          <div class="post-actions" v-if="showAllComments">
            <span>
              <feather-icon icon="ThumbsUpIcon" class="mr-05" size="15" /> Like
            </span>
            <span>
              <feather-icon icon="MessageSquareIcon" class="mr-05" size="15" />
              Comment
            </span>
            <span>
              <feather-icon icon="Share2Icon" class="mr-05" size="15" /> Share
            </span>
          </div>
        </b-col>

        <b-col
          v-if="showAllComments"
          cols="8"
          class="d-flex flex-column post-comments"
          :offset="2"
        >
        
          <RecursiveComment
            v-for="(comment, index) in G_CURRENT_FB_COMMENT_CHAT"
            :key="index"
            :comment="comment"
            :firstParent="comment"
            :depth="0"
          />
        </b-col>
      </b-row>
    </perfect-scrollbar>
  </div>
</template> 
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
import Spinner from "@/views/commons/Messenger/components/messenger/messages/Spinner.vue";
import HeaderSkeleton from "@/views/commons/Messenger/components/messenger/messages/HeaderSkeleton.vue";
import NestNotification from "@/socket/nest.notification.js";
import RecursiveComment from "@/views/commons/Messenger/components/facebook/Comments.vue";

export default {
  name: "FacebookPostCommentCurrentChat",
  components: {
    PerfectScrollbar,
    ChangeSms,
    Spinner,
    HeaderSkeleton,
    RecursiveComment,
  },
  data() {
    return {
      showAllComments: true,
      socket: null,
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_FB_COMMENT_CHAT: "MessengerMetaStore/G_CURRENT_FB_COMMENT_CHAT",
      G_CURRENT_FB_COMMENT_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_FB_COMMENT_SIDEBAR",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
    }),

    isChangeOfProfilePicture() {
      if (this.G_CURRENT_FB_COMMENT_SIDEBAR.story) {
        let textMatch = "updated their profile picture.";
        return this.G_CURRENT_FB_COMMENT_SIDEBAR.story.includes(textMatch);
      } else {
        return false;
      }
    },
  },
  async created() {
    this.initWebsocket();
  },
  mounted() {
    this.activateSocket();
  },
  async destroyed() {
    this.socket.disconnect();
  },
  methods: {
    ...mapActions({
      A_UPDATE_FB_COMMENT_CHAT: "MessengerMetaStore/A_UPDATE_FB_COMMENT_CHAT",
    }),
    initWebsocket() {
      this.socket = NestNotification.initWebsocket();
    },
    async activateSocket() {
      try {
        this.socket.on("meta-fb-comments", (data) => {
          const { main_comment_id, page_id, comment } = data;

          if (this.G_CURRENT_FB_PAGE.id !== page_id) return;

          let commentIsInChat = this.G_CURRENT_FB_COMMENT_CHAT.some(
            (comment) => comment.id === main_comment_id
          );

          if (!commentIsInChat) return;

          this.A_UPDATE_FB_COMMENT_CHAT(comment);
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async handleScroll() {},
  },
};
</script>

<style lang="scss">
.fb-current-chat-container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  background: #fff;
  border-left: 1px solid #eeeeee;
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  flex-direction: column;

  .mr-05 {
    margin-right: 0.5rem;
  }

  .custom-messages-scrollbar {
    margin-top: 0.2rem;
    height: 75vh;
    overflow-y: scroll;
    padding: 1rem;

    .ps-container {
      height: 100%;
      position: relative;
    }
    .ps__thumb-y {
      background-color: var(--primary-color) !important;
    }
  }

  .header {
    width: 100%;
    // max-width: 100%;
    // display: flex;
    // flex-direction: column;
    // flex-grow: 1;
    // flex-basis: 100%;
    // width: 100% !important;
    border-bottom: 1px solid #eeeeee;
    font-size: large;
    // max-height: 13%;
    // overflow: hidden;
    // justify-content: center;

    .header-avatar {
      display: inline-block;
      margin: 0rem 1rem;
    }

    .header-info {
      display: flex;
      flex-direction: column;
      min-width: 0; /* Important for text truncation */
    }

    .header-text-bold {
      font-weight: 900;
      font-size: medium;
      white-space: nowrap; /* Keep text on a single line */
      overflow: hidden; /* Hide any overflow */
      text-overflow: ellipsis; /* Add ellipsis (...) */
      min-width: 0; /* Prevent flex from causing overflow */
    }

    .header-stats {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap; /* Prevent line breaks */

      span {
        font-size: 0.9rem;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
      }

      .hyphen-spacing {
        margin: 0 0.5rem;
      }
    }
  }

  .post {
    margin-top: 1rem;

    .post-content {
      background: #f8f8f8;
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;

      .post-header {
        span:nth-of-type(1) {
          margin: 1rem 0rem 0.1rem 1rem;
          font-weight: 900;
          font-size: 1rem;
        }
        span:nth-of-type(2) {
          margin: 0rem 0rem 1rem 1rem;
          font-weight: 500;
          font-size: 0.9rem;
        }
      }

      .fb-post-image {
        width: 100%;
        height: auto;
      }

      .fb-post-video {
        width: 100%;
        height: 60vh !important;
        max-height: 60vh !important;
      }
    }

    .post-actions {
      display: flex;
      align-items: center;
      flex-direction: row;
      background: #f8f8f8;
      justify-content: space-between;
      border-top: 1px solid #605f5f;
      border-bottom: 1px solid #605f5f;
      padding: 0.5rem 3rem 0.5rem 3rem;
      margin: 0rem 1rem;

      span {
        cursor: pointer;
        font-size: 1rem;
        font-weight: 900;
        font-family: "Montserrat", sans-serif;
      }
    }

    .post-comments {
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      background: #f8f8f8;
      padding: 1rem;
    }

    .post-message {
      margin: 1rem 1rem 1rem 1rem;
      font-size: 1.2rem;
    }

    .attachments-container {
      // background: #000;
      //   padding: 1rem;
    }

    .count-comments {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      margin: 1rem;

      span {
        margin-right: 0.5rem;
      }
    }
  }
}

.dark-layout {
  .fb-current-chat-container {
    background: #111113;
    border-left: 1px solid #313131;

    .header {
      border-bottom: 1px solid #313131;
    }

    .post {
      .post-content {
        background: #313131;
      }

      .post-actions {
        background: #313131;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
      }

      .post-comments {
        background: #313131;
      }
    }
  }
}
</style>