<template>
  <div class="default-responses-messenger">
    <PredefinedAnswersList
      v-if="showAnswerList"
      @addAnswer="(message) => addAnswer(message)"
      @addNewAnswer="addNewAnswer()"
      @editAnswer="(answer) => editAnswer(answer)"
    />
    <AddPredefinedAnswers
      v-if="showAddNewAnswer"
      :answer="answer"
      :edit="!!answer._id"
      @cancel="cancel()"
    />
  </div>
</template>
<script>
import PredefinedAnswersList from "@/views/commons/Messenger/components/messenger/predefined-answers/components/PredefinedAnswersList.vue";
import AddPredefinedAnswers from "@/views/commons/Messenger/components/messenger/predefined-answers/components/AddPredefinedAnswers.vue";

export default {
  name: "PredefinedAnswers",
  components: {
    PredefinedAnswersList,
    AddPredefinedAnswers,
  },
  data() {
    return {
      showAnswerList: true,
      showAddNewAnswer: false,
      answer: {},
    };
  },
  methods: {
    addAnswer(message) {
      this.$emit("addAnswer", message);
    },
    addNewAnswer() {
      this.showAnswerList = false;
      this.showAddNewAnswer = true;
    },
    cancel() {
      this.showAnswerList = true;
      this.showAddNewAnswer = false;
      this.answer = {};
    },
    editAnswer(answer) {
      this.answer = answer;
      this.showAnswerList = false;
      this.showAddNewAnswer = true;
    },
  },
};
</script>
<style lang="scss">
.default-responses-messenger {
  user-select: none;
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 1rem;
  width: 30rem;
  .ps-container {
    height: 100%;
    position: relative;
    .ps__thumb-y {
      background-color: var(--primary-color);
    }
  }
}

.dark-layout {
  .default-responses-messenger {
    background-color: #17171a;
  }
}
</style>
