<template>
  <div class="message-box-container">
    <b-avatar
      class="current-fb-page-avatar"
      size="32"
      variant="light-secondary"
      :src="G_CURRENT_FB_PAGE.picture"
    >
    </b-avatar>

    <div v-if="sending" class="spinner-send-message">
      <b-spinner
        v-for="n in 3"
        :key="n"
        small
        label="Small Spinner"
        type="grow"
      ></b-spinner>
    </div>

    <div class="actions-send-message">
      <div
        v-if="validateMessage()"
        class="button-send-message"
        :class="{ 'cursor-pointer-disabled': sending }"
        @click="sendMessage()"
      >
        <strong>Send</strong>
      </div>

      <div ref="emojiContainer" class="emoji-container">
        <feather-icon
          icon="SmileIcon"
          size="20"
          class="cursor-pointer"
          :class="{ 'cursor-pointer-disabled': sending }"
          v-b-tooltip.hover.bottom="'Insert an emoji'"
          @click="toggleVisibility('emojiSelector')"
        />

        <EmojiSelector
          v-if="toggleStates.emojiSelector.visible"
          class="emoji-selector"
          @addEmoji="(character) => addEmoji(character)"
        />
      </div>

      <div
        v-if="!validateMessage()"
        ref="defaultResponses"
        class="default-responses-container"
      >
        <feather-icon
          icon="MessageSquareIcon"
          size="20"
          class="cursor-pointer"
          :class="{ 'cursor-pointer-disabled': sending }"
          v-b-tooltip.hover.bottom="'Insert a saved answer'"
          @click="toggleVisibility('defaultResponses')"
        />

        <PredefinedAnswers
          v-if="toggleStates.defaultResponses.visible"
          class="default-responses"
          @addAnswer="(message) => addAnswer(message)"
        />
      </div>

      <div class="container-files">
        <feather-icon
          v-if="!validateMessage()"
          icon="PaperclipIcon"
          size="20"
          class="cursor-pointer"
          v-b-tooltip.hover.bottom="'Attach a file'"
          @click="showUploadFiles = !showUploadFiles"
        />

        <UploadAttachment
          v-if="showUploadFiles"
          class="drag-and-drop-message"
          @sendAttachments="(file) => sendAttachments(file)"
          @cancelUpload="showUploadFiles = false"
        />
      </div>

      <span v-if="validateMessage()">
        {{ message.length }}/{{ maxLenght }}
      </span>
    </div>

    <b-form-textarea
      class="message-box"
      v-model="message"
      placeholder="Reply in Instagram..."
      no-resize
      :disabled="isDisabled"
      :maxlength="maxLenght"
      @keydown.enter="onEnterKey"
      @keydown.enter.shift.exact.prevent="message += '\n'"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import InstagramMetaService from "@/views/commons/Messenger/components/instagram/services/instagram.meta.service.js";
import EmojiSelector from "@/views/commons/components/customer-tickets/chat-components/EmojiSelector.vue";
import PredefinedAnswers from "@/views/commons/Messenger/components/messenger/predefined-answers/PredefinedAnswers.vue";
import UploadAttachment from "@/views/commons/Messenger/components/messenger/messages/components/UploadAttachment.vue";

export default {
  name: "MessageBox",
  components: {
    EmojiSelector,
    PredefinedAnswers,
    UploadAttachment,
  },
  data() {
    return {
      message: "",
      sending: false,
      maxLenght: 500,
      toggleStates: {
        emojiSelector: {
          visible: false,
          ref: "emojiContainer",
        },
        defaultResponses: {
          visible: false,
          ref: "defaultResponses",
        },
      },
      showUploadFiles: false,
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      CURRENT_USER: "auth/currentUser",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR",
    }),
    isDisabled() {
      return this.sending || this.showUploadFiles || this.isBlocked;
    },
    senderId() {
      return this.G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.sender.id;
    },
    isBlocked() {
      return (
        this.G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.classification ===
        "BLOCKED"
      );
    },
  },
  created() {},
  mounted() {},
  methods: {
    onEnterKey(event) {
      if (!event.shiftKey && this.validateMessage()) {
        event.preventDefault();
        this.sendMessage();
      }
    },

    async sendMessage() {
      try {
        this.sending = true;
        const params = {
          pageId: this.G_CURRENT_FB_PAGE.id,
          message: this.message,
          senderId: this.senderId,
          user: {
            id: this.CURRENT_USER.user_id,
            name: this.CURRENT_USER.fullName,
          },
        };

        const { data } = await InstagramMetaService.sendMessage(params);
        if (data.errorMessage) {
          this.showErrorSwal(data.errorMessage);
          return;
        }
        this.clearItems();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.sending = false;
      }
    },

    async sendAttachments(file) {
      try {
        this.addPreloader();
        const params = {
          pageId: this.G_CURRENT_FB_PAGE.id,
          senderId: this.senderId,
          user: {
            id: this.CURRENT_USER.user_id,
            name: this.CURRENT_USER.fullName,
          },
          attachment: {
            file: file.fileapproved,
            name: file.name,
            mimeType: file.type,
            size: file.size,
          },
        };

        const { data } = await InstagramMetaService.sendAttachments(params);
        if (data.errorMessage) {
          this.showErrorSwal(data.errorMessage);
          return;
        }
        this.clearItems();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.removePreloader();
      }
    },

    handleError(error) {
      const { response } = error;
      const { data } = response || {};
      const { response: responseData } = data || {};
      const { error: errorResponse } = responseData || {};

      const errorMessages = {
        551: () => {
          this.showInfoSwal(data.message);
          this.clearItems();
        },
        100: () => {
          this.showErrorSwal(data.message);
          this.clearItems();
        },
        400: () => this.showWarningSwal(data.message),
        10: () => {
          this.showInfoSwal(data.message);
          this.clearItems();
        },
        4: () => this.showInfoSwal(errorResponse.message),
      };

      if (data && errorMessages[data.code]) {
        errorMessages[data.code]();
        return;
      }

      if (errorMessages[errorResponse.code]) {
        errorMessages[errorResponse.code]();
        return;
      }

      this.showErrorSwal(error);
    },

    addEmoji(character) {
      this.message += character;
    },

    toggleVisibility(type) {
      const toggleState = this.toggleStates[type];
      if (!toggleState) return;
      this.showUploadFiles = false;
      toggleState.visible = !toggleState.visible;
      if (toggleState.visible) {
        document.addEventListener(
          "click",
          this.handleClickOutside.bind(this, type)
        );
      } else {
        document.removeEventListener(
          "click",
          this.handleClickOutside.bind(this, type)
        );
      }
    },

    hide(type) {
      const toggleState = this.toggleStates[type];
      if (!toggleState) return;

      toggleState.visible = false;
      document.removeEventListener(
        "click",
        this.handleClickOutside.bind(this, type)
      );
    },

    handleClickOutside(type, event) {
      const toggleState = this.toggleStates[type];
      if (!toggleState) return;

      const container = this.$refs[toggleState.ref];
      if (container && !container.contains(event.target)) {
        this.hide(type);
      }
    },

    validateMessage() {
      return this.message.trim() !== "";
    },

    addAnswer(message) {
      this.message = message;
      this.hide("defaultResponses");
    },

    clearItems() {
      this.sending = false;
      this.message = "";
      this.hide("emojiContainer");
      this.hide("defaultResponses");
      this.showUploadFiles = false;
    },
  },
  watch: {
    G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR() {
      this.clearItems();
    },
  },
};
</script>
<style lang="scss" scoped>
.message-box-container {
  position: relative;
  margin-top: 1rem;
  bottom: 1rem;
  padding: 0 1rem;
  width: 100%;
  height: 10%;
  .textarea-wrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .message-box {
    display: inline-block !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0.7rem 1rem 0.7rem 3.6rem !important;
  }
  .current-fb-page-avatar {
    position: absolute;
    left: 1.7rem;
    top: 0.5rem;
  }
  .spinner-send-message {
    position: absolute;
    left: 50%;
    top: 2rem;
    z-index: 1;
    .spinner-grow.spinner-grow-sm {
      margin-right: 0.5rem;
      width: 0.7rem;
      height: 0.7rem;
      background-color: #0099dd !important;
    }
  }
  .actions-send-message {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 0.5rem;
    right: 2rem;
    bottom: 0.5rem;
    z-index: 99;
    .emoji-container {
      .emoji-selector {
        position: absolute !important;
        bottom: 1.4rem;
        right: 0;
        z-index: 999;
        box-shadow: 0px 3px 8px 0px #dbdbdb;
      }
    }
    .default-responses-container {
      .default-responses {
        position: absolute !important;
        bottom: 1.4rem;
        right: 2rem;
        z-index: 999;
        box-shadow: 0px 3px 8px 0px #dbdbdb;
      }
    }
    .container-files {
      .drag-and-drop-message {
        position: absolute !important;
        bottom: 1.4rem;
        right: 4rem;
        z-index: 999;
        box-shadow: 0px 3px 8px 0px #dbdbdb;
      }
    }
    .button-send-message {
      padding: 0.1rem 0.4rem;
      border-radius: 0.3rem;
      cursor: pointer;
      font-weight: bold;
      font-size: 1rem;
      &:hover {
        background-color: #d6d6d6;
      }
    }
    .cursor-pointer-disabled {
      cursor: not-allowed !important;
      pointer-events: none !important;
      user-select: none !important;
    }
  }
}

.dark-layout {
  .message-box-container {
    background: #111113;
    .actions-send-message {
      .emoji-container,
      .default-responses-container,
      .container-files {
        .emoji-selector,
        .default-responses,
        .drag-and-drop-message {
          box-shadow: 0px 3px 8px 0px #0e0e0e;
        }
      }
      .button-send-message {
        &:hover {
          background-color: #1f1f1f;
        }
      }
    }
  }
}
</style>