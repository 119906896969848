<template>
  <div class="messenger-list-container">
    <div class="messenger-sidebar">
      <b-dropdown
        class="page-dropdown-container"
        size="lg"
        dropright
        variant="link"
        toggle-class="page-dropdown-toggle-class"
        no-caret
      >
        <template #button-content>
          <b-avatar
            v-if="G_CURRENT_FB_PAGE.picture"
            size="32"
            variant="light-secondary"
            class="cursor-pointer"
            :src="G_CURRENT_FB_PAGE.picture"
          />
        </template>
        <b-dropdown-item
          href="#"
          v-for="item in G_FB_PAGES"
          :key="item.id"
          @click="handleChangeProgram(item)"
          :class="G_CURRENT_FB_PAGE.id == item.id ? 'active-dropdown-item' : ''"
        >
          <div class="container-items-dropdown">
            <span :class="G_CURRENT_FB_PAGE.id == item.id ? ' text-white' : ''">
              {{ item.name }}
            </span>
            <b-badge
              v-if="getUnreadCountByPage(item.id)"
              variant="danger"
              class="unread-count"
            >
              {{ getUnreadCountByPage(item.id) }}
            </b-badge>
          </div>
        </b-dropdown-item>
      </b-dropdown>
      <div
        class="messenger-sidebar-options"
        :class="item.id == G_CURRENT_MESSENGER_META_TAB.id ? 'is-active' : ''"
        v-for="item in G_MESSENGER_META_TABS"
        :key="item.id"
        @click="handleChangeOption(item)"
      >
        <feather-icon
          v-if="item.id == 'instagram'"
          icon="InstagramIcon"
          class="messenger-sidebar-options-item"
          v-b-tooltip.hover.right
          :title="item.name"
          size="20"
          :badge="getCountUnreadInstagram"
          badge-classes="badge-danger"
        />
        <amg-icon
          v-if="item.id == 'messenger'"
          class="messenger-sidebar-options-item"
          icon="MessengerIcon"
          size="20"
          v-b-tooltip.hover.right="item.name"
          :badge="getCountUnreadMessenger"
          badge-classes="badge-danger"
        />
        <comments-facebook-icon
          v-if="item.id == 'facebook_comments'"
          class="messenger-sidebar-options-item"
          v-b-tooltip.hover.right="item.name"
        />
        <comments-instagram-icon
          v-if="item.id == 'instagram_comments'"
          class="messenger-sidebar-options-item"
          v-b-tooltip.hover.right="item.name"
        />
        <spam-icon
          v-if="item.id == 'spam'"
          class="messenger-sidebar-options-item"
          v-b-tooltip.hover.right="item.name"
        />
        <feather-icon
          v-if="item.id == 'lists'"
          icon="UsersIcon"
          class="messenger-sidebar-options-item"
          v-b-tooltip.hover.right="item.name"
          size="20"
        />
        <feather-icon
          v-if="item.id == 'classification'"
          icon="TagIcon"
          class="messenger-sidebar-options-item"
          v-b-tooltip.hover.right="item.name"
          size="20"
        />
        <feather-icon
          v-if="item.id == 'privacy_policy'"
          icon="ShieldIcon"
          class="messenger-sidebar-options-item"
          v-b-tooltip.hover.right="item.name"
          size="20"
          href="https://amgbusiness.us/privacy-policy"
          target="_blank"
        />
      </div>
    </div>

    <div class="chat-list">
      <div class="chat-list-header">
        <span>{{ G_CURRENT_MESSENGER_META_TAB.name }}</span>
        <b-button
          v-if="inListsTab"
          variant="primary"
          size="sm"
          class="ml-2"
          @click="showModalCreateList = true"
        >
          <feather-icon icon="PlusIcon" /> List
        </b-button>
        <template v-if="initMessengerMetaFilters">
          <span>|</span>
          <span>
            {{ G_CURRENT_MESSENGER_META_FILTER.name }}
          </span>
          <!-- rate limit -->
          <b-badge
            v-if="!isLoading"
            :variant="rateLimit.variant"
            v-b-tooltip.hover="rateLimit.tooltip"
            class="cursor-info"
          >
            {{ rateLimit.requests }} / {{ rateLimit.maxRequests }}
          </b-badge>
          <b-spinner v-if="isLoading" small variant="primary" />

          <div style="position: relative; margin-left: auto">
            <feather-icon
              class="cursor-pointer"
              icon="SlidersIcon"
              style="transform: rotate(90deg)"
              size="18"
              v-b-tooltip.hover.right="`Filters`"
              @click="showModalFilters = true"
            />
            <div
              v-if="activeSearchAdvanced > 0"
              style="
                position: absolute;
                height: 14px;
                width: 14px;
                top: -10px;
                right: -10px;
                border-radius: 50px;
                font-size: 10px;
                color: white;
              "
              class="font-weight-bolder bg-primary d-flex align-items-center justify-content-center"
            >
              {{ activeSearchAdvanced }}
            </div>
          </div>
        </template>
      </div>

      <ClassificationChats
        v-if="G_CURRENT_MESSENGER_META_TAB.id == 'classification'"
      />

      <div class="chat-searchbar" v-if="!inListsTab && !inClassification">
        <b-form-input
          v-model="searchChat"
          id="chat-searchbar-input"
          type="text"
          placeholder="Search..."
          @keydown="handleKeyDown"
        />
      </div>

      <div class="chat-filters" v-if="initMessengerMetaFilters">
        <div
          class="chat-filter-item"
          v-for="(item, index) in G_MESSENGER_META_FILTERS"
          :key="index"
          @click="handleChangeFilter(item.id)"
          :class="
            G_CURRENT_MESSENGER_META_FILTER.id == item.id
              ? 'chat-filter-item-active'
              : ''
          "
        >
          <feather-icon
            v-if="item.id == 'unread'"
            icon
            :badge="getCountUnreadMessages"
            badge-classes="badge-danger counter-unread"
          />
          <span>{{ item.name }}</span>
        </div>
      </div>

      <div class="message-list">
        <MessengerChatList
          v-if="G_CURRENT_MESSENGER_META_TAB.id == 'messenger'"
        />
        <InstagramChatList
          v-if="G_CURRENT_MESSENGER_META_TAB.id == 'instagram'"
        />
        <InstagramCommentsSidebar
          v-if="G_CURRENT_MESSENGER_META_TAB.id == 'instagram_comments'"
        />
        <FacebookCommentsSidebar
          v-if="G_CURRENT_MESSENGER_META_TAB.id == 'facebook_comments'"
        />
        <MetaLists v-if="G_CURRENT_MESSENGER_META_TAB.id == 'lists'" />
      </div>
    </div>

    <MetaCreateList
      v-if="showModalCreateList"
      @hidden="showModalCreateList = false"
    />

    <ModalFilters v-if="showModalFilters" @hidden="showModalFilters = false" />
  </div>
</template> 
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions, mapMutations } from "vuex";
import SpamIcon from "@/assets/images/icons/meta-inbox/SpamIcon.vue";
import CommentsFacebookIcon from "@/assets/images/icons/meta-inbox/CommentsFacebookIcon.vue";
import CommentsInstagramIcon from "@/assets/images/icons/meta-inbox/CommentsInstagramIcon.vue";
import InstagramCommentsSidebar from "@/views/commons/Messenger/components/instagram/InstagramCommentsSidebar.vue";
import MessengerChatList from "@/views/commons/Messenger/components/messenger/ChatList.vue";
import InstagramChatList from "@/views/commons/Messenger/components/instagram/ChatList.vue";
import MetaLists from "@/views/commons/Messenger/components/lists/MetaLists.vue";
import FacebookCommentsSidebar from "@/views/commons/Messenger/components/facebook/FacebookCommentsSidebar.vue";
import MetaCreateList from "@/views/commons/Messenger/components/lists/MetaCreateList.vue";
import ClassificationChats from "@/views/commons/Messenger/components/classification/ClassificationChats.vue";
import ModalFilters from "@/views/commons/Messenger/components/modals/ModalFilters.vue";
import MessengerMetaService from "@/views/commons/Messenger/components/services/messenger.meta.service.js";
import NestNotification from "@/socket/nest.notification.js";
import moment from "moment";
import { set } from "date-fns";
import { th } from "date-fns/locale";

export default {
  name: "MessengerList",
  components: {
    PerfectScrollbar,
    SpamIcon,
    CommentsFacebookIcon,
    CommentsInstagramIcon,
    InstagramCommentsSidebar,
    MessengerChatList,
    InstagramChatList,
    MetaLists,
    FacebookCommentsSidebar,
    MetaCreateList,
    ModalFilters,
    ClassificationChats,
  },
  data() {
    return {
      searchChat: "",
      showList: false,
      showMessageNoChat: true,
      showModalCreateList: false,
      showModalFilters: false,
      rateLimit: {},
      socket: null,
      isLoading: false,
      intervall: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_FB_PAGES: "MessengerMetaStore/G_FB_PAGES",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_MESSENGER_META_TABS: "MessengerMetaStore/G_MESSENGER_META_TABS",
      G_CURRENT_MESSENGER_META_TAB:
        "MessengerMetaStore/G_CURRENT_MESSENGER_META_TAB",
      G_MESSENGER_META_FILTERS: "MessengerMetaStore/G_MESSENGER_META_FILTERS",
      G_CURRENT_MESSENGER_META_FILTER:
        "MessengerMetaStore/G_CURRENT_MESSENGER_META_FILTER",
      G_MESSENGER_UNREAD_CHATS_COUNTER:
        "MessengerMetaStore/G_MESSENGER_UNREAD_CHATS_COUNTER",
      G_MESSENGER_META_FILTERS_ADVANCED:
        "MessengerMetaStore/G_MESSENGER_META_FILTERS_ADVANCED",
      G_SEARCH_TEXT: "MessengerMetaStore/G_SEARCH_TEXT",
      G_INSTAGRAM_UNREAD_CHATS_COUNTER:
        "MessengerMetaStore/G_INSTAGRAM_UNREAD_CHATS_COUNTER",
    }),

    inListsTab() {
      return this.G_CURRENT_MESSENGER_META_TAB.id == "lists";
    },

    inClassification() {
      return this.G_CURRENT_MESSENGER_META_TAB.id == "classification";
    },

    initMessengerMetaFilters() {
      const permittedTabs = ["messenger", "instagram"];
      return permittedTabs.includes(this.G_CURRENT_MESSENGER_META_TAB.id);
    },

    getCountUnreadMessenger() {
      if (this.G_MESSENGER_UNREAD_CHATS_COUNTER.length == 0) return 0;
      const unread = this.G_MESSENGER_UNREAD_CHATS_COUNTER.find(
        (item) => item._id == this.G_CURRENT_FB_PAGE.id
      );
      const count = unread ? unread.count : 0;
      return count < 100 ? count : "+99";
    },
    getCountUnreadInstagram() {
      if (this.G_INSTAGRAM_UNREAD_CHATS_COUNTER.length == 0) return 0;
      const unread = this.G_INSTAGRAM_UNREAD_CHATS_COUNTER.find(
        (item) => item._id == this.G_CURRENT_FB_PAGE.id
      );
      const count = unread ? unread.count : 0;
      return count < 100 ? count : "+99";
    },
    getCountUnreadMessages() {
      if (this.G_CURRENT_MESSENGER_META_TAB.id == "messenger") {
        return this.getCountUnreadMessenger;
      }
      if (this.G_CURRENT_MESSENGER_META_TAB.id == "instagram") {
        return this.getCountUnreadInstagram;
      }
      return 0;
    },

    activeSearchAdvanced() {
      let counter = 0;
      if (this.G_MESSENGER_META_FILTERS_ADVANCED.fromDate) {
        counter++;
      }
      if (this.G_MESSENGER_META_FILTERS_ADVANCED.toDate) {
        counter++;
      }
      if (this.G_MESSENGER_META_FILTERS_ADVANCED.classification) {
        counter++;
      }
      if (this.G_MESSENGER_META_FILTERS_ADVANCED.category) {
        counter++;
      }
      return counter;
    },
  },
  created() {
    this.socket = NestNotification.initWebsocket();
  },
  mounted() {
    this.socketOn();
    this.getRateLimit();
  },
  methods: {
    ...mapActions({
      A_SET_CURRENT_FB_PAGE: "MessengerMetaStore/A_SET_CURRENT_FB_PAGE",
      A_SET_CURRENT_MESSENGER_META_TAB:
        "MessengerMetaStore/A_SET_CURRENT_MESSENGER_META_TAB",
      A_SET_CURRENT_MESSENGER_META_FILTER:
        "MessengerMetaStore/A_SET_CURRENT_MESSENGER_META_FILTER",
      A_SET_SEARCH_TEXT: "MessengerMetaStore/A_SET_SEARCH_TEXT",
    }),
    ...mapMutations({
      M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT:
        "MessengerMetaStore/M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT",
      M_SET_CURRENT_FB_COMMENT_CHAT:
        "MessengerMetaStore/M_SET_CURRENT_FB_COMMENT_CHAT",
    }),
    handleChangeProgram(item) {
      this.A_SET_CURRENT_FB_PAGE(item);
      // reset current chats on change tab
      this.M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT(null);
      this.M_SET_CURRENT_FB_COMMENT_CHAT(null);
    },
    handleChangeOption(option) {
      const option_id = option.id;
      if (option_id == "privacy_policy") {
        window.open(option.url, "_blank");
        return;
      }
      this.A_SET_CURRENT_MESSENGER_META_TAB(option_id);
      this.A_SET_CURRENT_MESSENGER_META_FILTER("all");
    },
    handleChangeFilter(filter_id) {
      this.A_SET_CURRENT_MESSENGER_META_FILTER(filter_id);
    },

    async handleKeyDown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        this.A_SET_SEARCH_TEXT(this.searchChat);
      }
    },

    getUnreadCountByPage(pageId) {
      let total = 0;

      total +=
        this.G_MESSENGER_UNREAD_CHATS_COUNTER.find(
          (item) => item._id === pageId
        )?.count || 0;

      total +=
        this.G_INSTAGRAM_UNREAD_CHATS_COUNTER.find(
          (item) => item._id === pageId
        )?.count || 0;

      total = total < 100 ? total : "+99";
      return total;
    },
    async getRateLimit() {
      try {
        this.isLoading = true;
        const { data } = await MessengerMetaService.getRateLimit();
        this.processDataRateLimit(data);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    processDataRateLimit(data) {
      const { maxRequests, requests, startDate } = data;
      const pendingRequests = Math.max(0, maxRequests - requests);
      const variant = this.getVariantRateLimit(pendingRequests);

      const startDateMoment = moment(startDate);
      const endDate = startDateMoment.add(1, "hour");

      const calculateTimeDifference = () => endDate.diff(moment(), "minutes");

      const generateTooltipMessage = (diff) => {
        return pendingRequests > 0
          ? `Remaining requests: ${pendingRequests}. Limit resets in ${diff} minutes`
          : `Message sending limit reached. Resets in ${diff} minutes`;
      };

      if (this.intervall) clearInterval(this.intervall);

      //update tooltip message every minute
      const updateTooltip = () => {
        console.log("updateTooltip", requests, pendingRequests);
        const diff = calculateTimeDifference();
        this.rateLimit.tooltip = generateTooltipMessage(diff);
      };

      //init update tooltip
      this.intervall = setInterval(updateTooltip, 60000);

      const diff = calculateTimeDifference();

      this.rateLimit = {
        variant,
        maxRequests,
        requests,
        tooltip: generateTooltipMessage(diff),
        diff,
      };
    },
    getVariantRateLimit(pendingRequests) {
      const variants = {
        DANGER: "light-danger",
        WARNING: "light-warning",
        PRIMARY: "light-primary",
      };

      if (pendingRequests > 50) return variants.PRIMARY;
      if (pendingRequests > 10) return variants.WARNING;
      return variants.DANGER;
    },
    socketOn() {
      this.socket.on("rate-limit-meta", async (data) => {
        this.processDataRateLimit(data);
      });
    },
    clearItems() {
      this.searchChat = "";
      this.A_SET_SEARCH_TEXT("");
    },
  },
  watch: {
    G_CURRENT_FB_PAGE() {
      this.clearItems();
    },
    G_CURRENT_MESSENGER_META_FILTER() {
      this.clearItems();
    },
    G_MESSENGER_META_FILTERS_ADVANCED() {
      this.clearItems();
    },
    G_CURRENT_MESSENGER_META_TAB() {
      this.clearItems();
    },
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
};
</script>

<style lang="scss">
.messenger-list-container {
  height: 100%;
  background: #ffffff;
  max-width: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  overflow-x: hidden;

  .is-active {
    color: #0a7cff;
  }

  .active-dropdown-item {
    background-color: #0a7cff;
    color: #ffffff;
    pointer-events: none;
  }

  .messenger-sidebar {
    border-right: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    flex-basis: 50px;

    .dropdown.b-dropdown {
      border-radius: 0px !important;
    }
    .page-dropdown-container {
      width: 50px;
      height: 50px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      .container-items-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 0.5rem;
        .unread-count {
          border-radius: 50%;
        }
      }
    }

    .page-dropdown-toggle-class {
      text-decoration: none;
      padding: 0px;
      padding: 1rem 0;
    }
    .messenger-sidebar-options {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      position: relative;

      &hover {
        cursor: pointer;
        color: #0a7cff;
      }
      .messenger-sidebar-options-item {
        cursor: pointer;
        align-self: center;
        font-weight: 900;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
          sans-serif;
      }
    }
  }

  .chat-list {
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    .chat-list-header {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 1rem;
      gap: 0.5rem;

      span:nth-of-type(1),
      span:nth-of-type(2) {
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
        font-size: large;
        font-weight: 900;
      }

      span:nth-of-type(3) {
        color: #0a7cff;
        font-size: medium;
        font-weight: 900;
        font-family: "Montserrat", sans-serif;
      }
    }

    .chat-searchbar {
      display: flex;
      flex-direction: column;
      padding: 0rem 1rem;
      margin-bottom: 1rem;

      #chat-searchbar-input {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="18px" stroke="%23ffffff" height="18px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg>')
          no-repeat;
        padding: 0.5rem 0rem 0.5rem 3rem;
        background-position: 10px;
        border-radius: 10px !important;
        font-size: initial;
      }
    }

    .chat-filters {
      display: flex;
      justify-content: start;
      gap: 0.5rem;
      padding: 0rem 1rem;
      margin-bottom: 1rem;
      .chat-filter-item {
        padding: 4px 8px;
        background-color: #f8f9fb;
        border-radius: 4px;
        font-family: Helvetica, Arial, sans-serif;
        cursor: pointer;
        transition: background-color 0.3s ease;
        .counter-unread {
          left: 3.5rem;
          top: -1.2rem;
        }
      }
      .chat-filter-item-active {
        background-color: #e1edf7 !important;
        color: #0a78be !important;
        font-weight: 900;
      }
    }

    .message-list {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .ps-container {
        height: 100%;
        .ps__thumb-y {
          background-color: var(--primary-color);
        }
      }
    }
  }
}

.dark-layout {
  .messenger-list-container {
    background: #111113;
    .messenger-sidebar {
      border-right: 1px solid #313131;
      .page-dropdown-container {
        border-bottom: 1px solid #313131;
      }
    }

    .chat-list {
      .chat-filters {
        .chat-filter-item {
          background-color: #17171a;
        }
        .chat-filter-item-active {
          background-color: #1e1e22 !important;
          color: #0a78be !important;
          font-weight: 900;
        }
      }
    }
  }
}
</style>