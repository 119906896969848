<template>
  <b-modal
    ref="modalLinked"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    title="LINK LEAD"
    size="xsm"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
    body-class="p-0 pt-1"
  >
    <b-table-simple
      small
      responsive
      class="blue-scrollbar position-relative table-new-customization"
      sticky-header="50vh"
    >
      <b-thead>
        <b-tr>
          <b-th>Lead</b-th>
          <b-th class="text-center">Created By</b-th>
          <b-th>Actions</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="lead in leads" :key="lead.id">
          <b-td>
            <router-link
              :class="textLink"
              :to="`/socialnetwork/leads/new/dashboard/${lead.id}`"
              target="_blank"
            >
              {{ lead.nickname }}
            </router-link>
          </b-td>
          <b-td class="text-center">
            {{ lead.created_by }}
            <br />
            {{ lead.created_at | myGlobal }}
          </b-td>
          <b-td>
            <b-button variant="outline-warning" size="sm" @click="linked(lead)">
              <feather-icon icon="LinkIcon" size="14" />
              Link
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-modal>
</template>
<script>
export default {
  props: {
    leads: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.toggleModal("modalLinked");
  },
  created() {},
  computed: {
    routeModule() {
      return this.$route.meta.route;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    linked({ id }) {
      this.$emit("linked", id);
      this.$emit("close");
    },
  },
};
</script>