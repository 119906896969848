import { render, staticRenderFns } from "./CommentsFacebookIcon.vue?vue&type=template&id=115f52bb&scoped=true"
import script from "./CommentsFacebookIcon.vue?vue&type=script&lang=js"
export * from "./CommentsFacebookIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "115f52bb",
  null
  
)

export default component.exports