 <template>
  <b-modal
    v-model="showModal"
    size="xmd"
    @hidden="$emit('hidden')"
    hide-footer
    :title="G_CURRENT_META_LIST.name"
  >
    <div class="mass-messages">
      <filter-slot
        :total-rows="totalRowsSaved"
        :paginate="paginateSaved"
        :start-page="startPageSaved"
        :to-page="toPageSaved"
        :filter="[]"
        :filter-principal="filterPrincipalSaved"
        :doublePaginate="false"
        @onChangeCurrentPage="getAllLeadsFromList"
        @reload="getAllLeadsFromList"
      >
        <template #table>
          <div class="d-flex" v-if="!G_CURRENT_META_LIST.deletedBy">
            <!-- <div v-if="listOfLeads.length != 0 && !readOnly">
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteAll"
              >
                <feather-icon icon="Trash2Icon"></feather-icon> Delete All ({{
                  totalRowsSaved
                }})
              </b-button>
            </div>
            <div class="d-flex align-items-center ml-2">
              <b-button
                v-if="auxListLeadsDelete.length != 0"
                variant="outline-warning"
                @click="deleteLeadsFromList"
              >
                <feather-icon icon="Trash2Icon"></feather-icon> Delete ({{
                  auxListLeadsDelete.length
                }})
              </b-button>
            </div> -->
          </div>
          <b-row class="container-leads">
            <b-col
              lg="2"
              md="3"
              sm="6"
              cols="12"
              v-for="(element, index) in listOfLeads"
              :key="element + '-sav-' + index"
              style="margin: 8px 0"
            >
              <div class="lead-card-item border">
                <div class="w-100">
                  <div class="text-truncate">
                    <b-badge variant="light-primary">{{
                      element.sender.name | limitChars(30)
                    }}</b-badge>
                  </div>
                  <div>
                    <!-- <b-badge variant="light-primary">{{
                      element.account
                    }}</b-badge> -->
                  </div>
                  <div>
                    <!-- {{ element.mobile }} -->
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <!-- <div class="text-center" v-else>
            There are no records matching your request
          </div> -->
        </template>
      </filter-slot>
    </div>
  </b-modal>
</template>

  
  
<script>
import MetaLeadsService from "@/views/commons/Messenger/components/lists/services/meta-leads.service.js";

import { mapGetters } from "vuex";

export default {
  props: {
    listId: {
      type: [Number, String],
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showModal: true,
      listName: "",
      listOfLeads: [],
      auxListLeads: [],

      filterPrincipalSaved: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Name",
        model: "",
      },
      totalRowsSaved: 0,
      paginateSaved: {
        currentPage: 1,
        perPage: 50,
      },
      startPageSaved: 0,
      toPageSaved: 0,

      auxListLeadsDelete: [],
    };
  },

  computed: {
    ...mapGetters({
      G_CURRENT_META_LIST: "MessengerMetaStore/G_CURRENT_META_LIST",
    }),
  },

  methods: {
    selectElementDelete(id) {
      if (this.readOnly) return;

      const index = this.auxListLeadsDelete.indexOf(id);

      if (index !== -1) {
        this.auxListLeadsDelete.splice(index, 1);
      } else {
        this.auxListLeadsDelete.push(id);
      }
    },

    async deleteLeadsFromList() {
      const confirm = await this.showConfirmSwal();
      if (!confirm.value) return;

      try {
        this.addPreloader();
        const params = {
          list_delete: this.auxListLeadsDelete,
        };

        const { data, status } = await MassMessageService.deleteLeadsList(
          params
        );

        if (status == 200) {
          this.showSuccessSwal(null, data.title);
          this.getAllLeadsFromList();
          this.$emit("updateList");
          this.auxListLeadsDelete = [];
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getAllLeadsFromList() {
      try {
        this.addPreloader();
        const params = {
          listId: this.listId,
          perPage: this.paginateSaved.perPage,
          page: this.paginateSaved.currentPage,
          searchText: this.filterPrincipalSaved.model,
        };

        const { data } = await MetaLeadsService.getDetailMessage(params);
        this.startPageSaved = data.from ? data.from : 0;
        this.totalRowsSaved = data.total;
        this.toPageSaved = data.to ? data.to : 0;
        this.listOfLeads = data.data ? data.data : [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    // async deleteAll() {
    //   try {
    //     const confirm = await this.showConfirmSwal();
    //     if (!confirm.value) return;

    //     this.addPreloader();
    //     const params = {
    //       list_id: this.listId,
    //     };

    //     const { data, status } = await MassMessageService.deleteLeadsList(
    //       params
    //     );

    //     if (status == 200) {
    //       this.showSuccessSwal();
    //       this.getAllLeadsFromList();
    //       this.$emit("updateList");
    //     }
    //   } catch (error) {
    //     this.showErrorSwal(error);
    //   } finally {
    //     this.removePreloader();
    //   }
    // },
  },
};
</script>

<style lang="scss">
.mass-messages {
  .table-responsive {
    overflow-x: initial;
  }

  .container-leads {
    border: 1px solid #1d1d20;
    margin: 20px 0;
    border-radius: 10px;
    padding: 0 10px;
  }

  .button-icon-ms,
  .lead-card-item {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #f3f2f7;
    color: #656565;
  }

  .button-icon-ms {
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px;
  }

  .lead-card-item {
    padding: 10px;
    justify-content: space-between;
    height: 100%;

    .icon-selected {
      color: #323232;
    }
  }

  .lead-card-item:hover,
  .button-icon-ms:hover {
    background-color: #cfefff;
  }

  .lead-card-item-active,
  .button-icon-ms-active {
    background-color: #cfefff;
  }

  .count-item {
    font-size: 12px;
  }
}

.dark-layout {
  .mass-messages {
    .container-leads {
      border: 1px solid #4b4b4b;
    }

    .lead-card-item,
    .button-icon-ms {
      background-color: #1d1d20;
      color: #ffffff;

      .icon-selected {
        color: #ffffff;
      }
    }

    .lead-card-item:hover,
    .button-icon-ms:hover {
      background-color: #323232;
      border: 1px solid #323232;
    }

    .lead-card-item-active,
    .button-icon-ms-active {
      background-color: #323232;
      border: 1px solid #323232;
    }

    .list-border-top {
      border-top: 1px solid #323232;
    }
  }
}
</style> 