<template>
  <div class="sidebar-header-container border-bottom pb-1 px-1">
    <div class="sidebar-header">
      <div
        v-if="G_CURRENT_MESSENGER_MESSAGE_SIDEBAR.type == 1"
        class="search-message"
      >
        <strong class="sidebar-title">Search Messages</strong>
      </div>
      <div v-else class="fb-user-profile">
        <UserProfile />
      </div>
      <span class="cursor-pointer" @click="$emit('close')">
        <tabler-icon icon="XIcon" size="16" />
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import UserProfile from "@/views/commons/Messenger/components/instagram/messages/components/UserProfile.vue";

export default {
  name: "SidebarHeader",
  components: {
    UserProfile,
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      G_CURRENT_MESSENGER_MESSAGE_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_MESSENGER_MESSAGE_SIDEBAR",
    }),
  },
};
</script>
<style lang="scss" scoped>
.sidebar-header-container {
  width: 100%;
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sidebar-title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}
</style>