<template>
  <div class="instagram-message-content">
    <perfect-scrollbar
      v-if="showMessages"
      ref="scrollContainerInstagram"
      @scroll="handleScroll"
    >
      <Spinner v-if="previousMessages" :centerHorizontal="true" />

      <Message
        v-for="(message, index) in INSTAGRAM_MESSAGES"
        :key="index"
        :message="message"
        :sender-avatar="senderAvatar"
      />

      <transition name="fade">
        <span v-if="showScrollDown" class="scroll-down">
          <div
            class="button-down"
            :class="{ 'new-message': newMessage > 0 }"
            @click="scrollToBottom()"
          >
            <tabler-icon
              icon="ChevronsDownIcon"
              class="text-danger"
              size="16"
            />
            <span v-if="newMessage > 0" class="text-danger"
              >{{ newMessage }} new message(s)</span
            >
          </div>
        </span>
      </transition>
    </perfect-scrollbar>

    <Spinner v-if="loading" :centerVertical="true" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import InstagramMetaService from "@/views/commons/Messenger/components/instagram/services/instagram.meta.service.js";
import PerfectScrollbar from "vue-perfect-scrollbar";
import Message from "@/views/commons/Messenger/components/messenger/messages/components/Message.vue";
import Spinner from "@/views/commons/Messenger/components/messenger/messages/Spinner.vue";
import NestNotification from "@/socket/nest.notification.js";
import moment from "moment";

export default {
  name: "MessageContent",
  components: {
    PerfectScrollbar,
    Message,
    Spinner,
  },
  data() {
    return {
      INSTAGRAM_MESSAGES: [],
      loading: false,
      perpage: 20,
      npage: 1,
      previousMessages: false,
      showScrollDown: false,
      showMessages: false,
      hasMoreData: true,
      socket: null,
      newMessage: 0,
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR",
      G_SEARCH_MESSAGE: "MessengerMetaStore/G_SEARCH_MESSAGE",
      CURRENT_USER: "auth/currentUser",
    }),
    chatId() {
      return this.G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.id;
    },
    allowedMessageTypes() {
      return ["Chat", "Unsupported", "MissedCall", "SuccessfulCall"];
    },
    lastMessageId() {
      const chatMessages = this.INSTAGRAM_MESSAGES.filter((message) =>
        this.allowedMessageTypes.includes(message.type)
      );
      if (chatMessages.length === 0) return null;
      const lastMessage = chatMessages[chatMessages.length - 1];
      if (lastMessage.me === true) return null;
      return lastMessage.id;
    },
    senderAvatar() {
      return this.G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.sender.imageUrl;
    },
  },
  created() {
    this.socket = NestNotification.initWebsocket();
  },
  mounted() {
    this.getMessages();
    this.socketOn();
  },
  methods: {
    ...mapActions({
      A_SET_SEARCH_MESSAGE: "MessengerMetaStore/A_SET_SEARCH_MESSAGE",
    }),

    async getMessages() {
      try {
        this.loading = true;
        const params = {
          searchText: "",
          chatId: this.chatId,
          npage: this.npage,
          perpage: this.perpage,
        };
        const { data } = await InstagramMetaService.getInstagramChatMessages(
          params
        );

        if (data.length === 0) {
          this.hasMoreData = false;
          return;
        }

        const chatMessages = this.previousMessages
          ? [...data, ...this.INSTAGRAM_MESSAGES]
          : data;

        this.groupAndAddDateMessage(chatMessages);
        this.showMessages = true;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
        this.previousMessages = false;
      }
    },
    handleScroll() {
      const container = this.$refs.scrollContainerInstagram.$el;
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      if (scrollTop <= 100) {
        if (!this.loading && this.hasMoreData) {
          this.previousMessages = true;
          this.npage += 1;
          this.loading = true;

          const prevScrollTop = container.scrollTop; // Save the current scroll position

          this.getMessages().then(() => {
            const newScrollHeight = container.scrollHeight;
            const scrollDifference = newScrollHeight - scrollHeight;
            container.scrollTop = prevScrollTop + scrollDifference; // Restore the scroll position
            this.loading = false;
          });
        }
      }
      const initialScrollTop = scrollHeight - clientHeight;
      this.showScrollDown = scrollTop < initialScrollTop - 10;
      if (scrollTop >= initialScrollTop && this.newMessage > 0) {
        this.markMessageAsSeen();
        this.newMessage = 0;
        this.showScrollDown = false;
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainerInstagram.$el;
        if (container) {
          setTimeout(() => {
            container.scrollTop = container.scrollHeight;
            if (this.newMessage > 0) {
              this.markMessageAsSeen();
              this.newMessage = 0;
              this.showScrollDown = false;
            }
          }, 200);
        }
      });
    },
    async searchMessage() {
      const searchMessageRecursive = async () => {
        const element = document.getElementById(this.G_SEARCH_MESSAGE.id);
        const container =
          this.$refs.scrollContainerInstagram.$el ||
          this.$refs.scrollContainerInstagram;

        if (element && container) {
          container.scrollTop = element.offsetTop;
          const childElement = element.querySelector(".bubble-message");
          if (childElement) {
            childElement.classList.add("bg-light-primary");
            setTimeout(() => {
              childElement.classList.remove("bg-light-primary");
              this.A_SET_SEARCH_MESSAGE("");
            }, 1000);
          }
        } else {
          if (this.hasMoreData && !this.loading) {
            this.previousMessages = true;
            this.npage += 1;
            await this.getMessages();
            await this.$nextTick();
            await searchMessageRecursive();
          }
        }
      };

      await this.$nextTick();
      await searchMessageRecursive();
    },
    async markMessageAsSeen() {
      try {
        if (!this.lastMessageId) return;

        const pageId = this.G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.pageId;

        const params = {
          pageId: pageId,
          chatId: this.chatId,
          senderId: this.G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.sender.id,
          user: {
            id: this.CURRENT_USER.user_id,
            name: this.CURRENT_USER.fullName,
          },
          lastMessageId: this.lastMessageId,
        };

        await InstagramMetaService.markMessageAsSeen(params);
      } catch (error) {
        console.error(`Error marking chat as read: ${error}`);
      }
    },
    clearItems() {
      this.loading = false;
      this.previousMessages = false;
      this.npage = 1;
      this.showScrollDown = false;
      this.showMessages = false;
      this.hasMoreData = true;
      this.newMessage = 0;
      this.INSTAGRAM_MESSAGES = [];
    },
    socketOn() {
      this.socket.on("meta-instagram", (data) => {
        const { message } = data;
        if (this.isCurrentChatMessage(message)) {
          const { messageStatus, me, user } = message;
          if (this.isMessageStatusToPush(messageStatus)) {
            this.INSTAGRAM_MESSAGES.push(message);
            this.groupAndAddDateMessage(this.INSTAGRAM_MESSAGES);
            this.showScrollDown = true;
            this.newMessage = !me ? this.newMessage + 1 : 0;

            setTimeout(() => {
              this.handleScroll(); //verify if the user is at the bottom of the chat
            }, 500);

            if (me && user && user.id == this.CURRENT_USER.user_id) {
              this.scrollToBottom();
            }
          }
          if (this.isMessageStatusToProcess(messageStatus)) {
            const STATUS_MESSAGE = {
              SENT: "Sent",
              READ: "Read",
              DELIVERED: "Delivered",
              ERROR: "Error",
            };

            if (messageStatus === STATUS_MESSAGE.READ && me) {
              this.INSTAGRAM_MESSAGES = this.INSTAGRAM_MESSAGES.map((msg) => {
                if (
                  msg.me === true &&
                  ![STATUS_MESSAGE.ERROR, STATUS_MESSAGE.READ].includes(
                    msg.messageStatus
                  )
                ) {
                  return { ...msg, messageStatus: STATUS_MESSAGE.READ };
                }
                return msg;
              });
              return;
            }

            if (messageStatus === STATUS_MESSAGE.DELIVERED && me) {
              this.INSTAGRAM_MESSAGES = this.INSTAGRAM_MESSAGES.map((msg) => {
                if (
                  msg.me === true &&
                  ![
                    STATUS_MESSAGE.DELIVERED,
                    STATUS_MESSAGE.ERROR,
                    STATUS_MESSAGE.READ,
                  ].includes(msg.messageStatus)
                ) {
                  return { ...msg, messageStatus: STATUS_MESSAGE.DELIVERED };
                }
                return msg;
              });
              return;
            }

            this.INSTAGRAM_MESSAGES = this.INSTAGRAM_MESSAGES.map((msg) => {
              if (msg.id === message.id) {
                return { ...msg, ...message };
              }
              return msg;
            });
          }
        }
      });
    },
    isCurrentChatMessage(message) {
      return message && this.chatId === message.chatId;
    },
    isMessageStatusToPush(messageStatus) {
      const statusToPush = ["Sent", "Error"];
      return statusToPush.includes(messageStatus);
    },
    isMessageStatusToProcess(messageStatus) {
      const statusToProcess = ["Read", "Delivered"];
      return statusToProcess.includes(messageStatus);
    },
    groupAndAddDateMessage(messages) {
      const tz = "America/Los_Angeles";
      //Step 1: Remove all Date messages
      messages = messages.filter((message) => message.type !== "Date");
      //Step 2: Group messages by date
      const groupedMessages = messages.reduce((acc, message) => {
        const date = moment(message.createdTime)
          .tz(tz)
          .startOf("day")
          .format("YYYY-MM-DD");
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(message);
        return acc;
      }, {});

      // Step 3: Create a new message with the lowest createdTime and add it to the beginning of each group
      const result = [];
      Object.keys(groupedMessages).forEach((date) => {
        const messagesForDate = groupedMessages[date];
        const minCreatedTimeMessage = messagesForDate.reduce((minMsg, msg) => {
          return new Date(msg.createdTime) < new Date(minMsg.createdTime)
            ? msg
            : minMsg;
        });

        const dateMessage = {
          id: "date_id_" + date,
          createdTime: minCreatedTimeMessage.createdTime,
          message: minCreatedTimeMessage.createdTime,
          type: "Date",
        };

        result.push(dateMessage);
        result.push(...messagesForDate);
      });

      this.INSTAGRAM_MESSAGES = [...result];
    },
  },
  watch: {
    G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR(newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.clearItems();
        this.getMessages();
      }
    },
    showMessages(newVal) {
      if (newVal) {
        this.scrollToBottom();
        this.markMessageAsSeen();
      }
    },
    G_SEARCH_MESSAGE(newVal) {
      if (!newVal) return;
      this.searchMessage();
    },
  },
  beforeDestroy() {
    this.clearItems();
    this.socket.disconnect();
  },
};
</script>
<style lang="scss">
.instagram-message-content {
  width: 100%;
  height: 78%;
  overflow: hidden;
  .ps-container {
    height: 100%;
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;
    .ps__thumb-y {
      background-color: var(--primary-color);
    }
    .scroll-down {
      position: absolute;
      right: 50%;
      bottom: 5rem;
      z-index: 999;

      .button-down {
        position: fixed;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        cursor: pointer;
        box-shadow: 0px 3px 8px 0px #dbdbdb;
      }
      .button-down.new-message {
        width: unset !important;
        height: unset !important;
        border-radius: 5rem !important;
        padding: 0.5rem;
        font-size: 0.9rem;
        font-weight: 900;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }
}
.dark-layout {
  .instagram-message-content {
    background: #111113;
    .scroll-down {
      .button-down {
        background-color: #17171a;
        box-shadow: 0px 3px 8px 0px #0e0e0e;
      }
    }
  }
}
</style>