<template>
  <div class="">
<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 2C4.23858 2 2 4.23858 2 7V14C2 16.7614 4.23858 19 7 19H8.586L12.879 23.293C13.06 23.474 13.324 23.5308 13.5504 23.4319C13.7768 23.333 13.922 23.1054 13.922 21.85V19.75C13.922 19.3358 14.2578 19 14.672 19H17C19.7614 19 22 16.7614 22 14V7C22 4.23858 19.7614 2 17 2H7Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="12" cy="11" r="3.5" stroke="currentColor" stroke-width="2"/>
    <circle cx="18" cy="6" r="1" fill="currentColor"/>
</svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>

<style lang="scss" scoped> 
</style>