import { serverlessApi } from "@/service/axios";
import { nestApi } from "@/service/axios";

class LeadsMetaService {
    prefix = '/meta/lists';

    async saveLeadsToList(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/leads`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async getChatsByPage(payload){
        try {
            const data = await nestApi.post(`${this.prefix}/leads/index`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getDetailMessage(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/leads/detail`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new LeadsMetaService();