<template>
  <div
    class="ig-current-chat-container d-flex justify-content-center align-items-center"
    v-if="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR === null"
  >
    No chat selected.
  </div>
  <div v-else class="ig-current-chat-container">
    <div class="header">
      <HeaderSkeleton
        v-if="
          G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR === null ||
          G_CURRENT_INSTAGRAM_COMMENT_CHAT === null
        "
      />

      <b-row no-gutters v-else>
        <b-col cols="6">
          <div class="d-flex justify-content-start align-items-center">
            <b-avatar
              v-if="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.media_type === 'VIDEO'"
              button
              size="4rem"
              class="header-avatar"
              :src="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.thumbnail_url"
            ></b-avatar>
            <b-avatar
              v-else
              button
              size="4rem"
              class="header-avatar"
              :src="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.media_url"
            ></b-avatar>
            <div class="header-info">
              <span class="header-text-bold">{{
                getChatName(G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.media_type)
              }}</span>

              <div class="header-stats">
                <span
                  >{{
                    G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.like_count
                  }}
                  likes</span
                >
                <span class="hyphen-spacing">-</span>
                <span
                  >{{
                    G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.comments_count
                  }}
                  comments</span
                >
                <span class="hyphen-spacing">-</span>
                <span>{{
                  G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.timestamp_formatted
                }}</span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-end align-items-center">
          <b-button variant="outline-secondary" class="mr-1">
            <feather-icon
              icon="AlertOctagonIcon"
              size="20"
              class="cursor-pointer"
              v-b-tooltip.hover="'Mark as spam'"
            ></feather-icon
          ></b-button>
          <b-button variant="outline-secondary" class="mr-1">
            <feather-icon
              icon="MailIcon"
              size="20"
              class="cursor-pointer"
              v-b-tooltip.hover="'Mark as unread'"
            ></feather-icon>
          </b-button>
          <b-button variant="outline-secondary" class="mr-1">
            <feather-icon
              icon="MailIcon"
              size="20"
              class="cursor-pointer"
              v-b-tooltip.hover="'Move to inbox'"
            ></feather-icon>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <Spinner
      v-if="G_CURRENT_INSTAGRAM_COMMENT_CHAT === null"
      :centerHorizontal="true"
      :centerVertical="true"
    />

    <b-row v-else no-gutters class="h-100">
      <b-col
        class="border-right"
        cols="9"
        v-if="G_CURRENT_INSTAGRAM_COMMENT_CHAT.length > 0"
      >
        <perfect-scrollbar
          class="custom-messages-scrollbar"
          ref="scrollContainer"
          @scroll="handleScroll"
        >
          <div
            class="instagram-message"
            v-for="(comment, index) in G_CURRENT_INSTAGRAM_COMMENT_CHAT"
            :key="index"
          >
            <div
              class="d-flex flex-row align-items-start justify-content-start w-100"
            >
              <b-avatar
                button
                size="1.7rem"
                class="header-avatar mr-1"
                :src="comment.from.profile_pic"
              ></b-avatar>

              <div class="d-flex flex-column w-100">
                <div class="comment-header">
                  <span> {{ comment.username }} </span>
                  <span> {{ comment.text }} </span>
                </div>
                <div class="comment-stats">
                  <span> {{ comment.timestamp_formatted }}</span>
                  <span v-if="comment.like_count > 0" class="cursor-pointer">
                    {{ comment.like_count }}
                    {{ comment.like_count > 1 ? "likes" : "like" }}
                  </span>
                  <span class="cursor-pointer"> Reply </span>
                  <span class="text-primary cursor-pointer">
                    Send message
                  </span>
                </div>
                <div class="comment-reply" v-if="comment.replies.length > 0">
                  <span
                    class="line"
                    @click="comment.display_replies = !comment.display_replies"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                  >

                  <span
                    v-if="!comment.display_replies"
                    class="view-reply-text"
                    @click="comment.display_replies = !comment.display_replies"
                  >
                    View replies ({{ comment.replies.length }})</span
                  >
                  <span
                    v-else
                    class="view-reply-text"
                    @click="comment.display_replies = !comment.display_replies"
                  >
                    Hide replies</span
                  >

                  <template v-if="comment.display_replies">
                    <div
                      class="instagram-message mt-1"
                      v-for="(reply, index) in comment.replies"
                      :key="index"
                    >
                      <div
                        class="d-flex flex-row align-items-start justify-content-start w-100"
                      >
                        <b-avatar
                          button
                          size="1.7rem"
                          class="header-avatar mr-1"
                          :src="reply.from.profile_pic"
                        ></b-avatar>

                        <div class="d-flex flex-column w-100">
                          <div class="comment-header">
                            <span> {{ reply.username }} </span>
                            <span> {{ reply.text }} </span>
                          </div>
                          <div class="comment-stats">
                            <span> {{ reply.timestamp_formatted }}</span>
                            <span
                              v-if="reply.like_count > 0"
                              class="cursor-pointer"
                            >
                              {{ reply.like_count }}
                              {{ comment.like_count > 1 ? "likes" : "like" }}
                            </span>
                            <span class="cursor-pointer"> Reply </span>
                            <span class="text-primary"> Send message </span>
                          </div>
                        </div>
                      </div>
                      <feather-icon
                        icon="HeartIcon"
                        size="20"
                        class="heart-icon"
                      ></feather-icon>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <feather-icon
              icon="HeartIcon"
              size="20"
              class="heart-icon"
            ></feather-icon>
          </div>
        </perfect-scrollbar>
      </b-col>

      <b-col class="border-right" cols="9" v-else>
        <perfect-scrollbar
          class="custom-messages-scrollbar"
          ref="scrollContainer"
        >
          <div class="d-flex align-items-center justify-content-center h-100">
            No comments found.
          </div>
        </perfect-scrollbar>
      </b-col>

      <b-col class="instagram-message p-1" cols="3">
        <div v-if="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.media_type === 'IMAGE'">
          <b-img
            class="cursor-pointer"
            @click="showImage(G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR)"
            :src="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.media_url"
            fluid-grow
          ></b-img>
        </div>

        <div v-if="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.media_type === 'VIDEO'">
          <video
            style="max-height: 30vh; width: 100%"
            controls
            :src="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.media_url"
          ></video>
        </div>

        <div
          v-if="
            G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.media_type === 'CAROUSEL_ALBUM'
          "
        >
          <b-carousel
            class="ig-carousel"
            v-model="slide"
            :interval="showImageViewer ? 0 : 4000"
            controls
            indicators
            background="#ababab"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide
              v-for="(
                image, index
              ) in G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.children"
              :key="index"
            >
              <template #img>
                <img
                  class="d-block img-fluid w-100 cursor-pointer"
                  width="1024"
                  height="480"
                  :src="image.media_url"
                  alt="image slot"
                  @click="
                    showImage(
                      G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.children,
                      index
                    )
                  "
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>

        <p class="mt-2 text-primary text-center">
          Go to
          <a
            :href="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.permalink"
            target="_blank"
            >Instagram</a
          >
        </p>

        <div class="d-flex justify-content-between align-items-center">
          <span class="font-weight-bold"
            >{{ G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.like_count }} likes.
          </span>
          <span
            >{{ G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.timestamp_formatted }}
          </span>
        </div>

        <hr />

        <span class="font-weight-bolder"
          >@{{ G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.username }}
        </span>

        <perfect-scrollbar class="custom-caption-scrollbar" ref="scrollCaption">
          <change-sms
            class="mb-1"
            v-if="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.caption"
            :sms-data="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.caption"
            :text-length="250"
            type-show-more="span"
            @isExpanded="handleScrollCaption"
          />
        </perfect-scrollbar>
      </b-col>
    </b-row>

    <ImagePreview
      v-if="showImageViewer"
      :images="viewerImages"
      @hidden="showImageViewer = false"
    />
  </div>
</template> 
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import Spinner from "@/views/commons/Messenger/components/messenger/messages/Spinner.vue";
import HeaderSkeleton from "@/views/commons/Messenger/components/messenger/messages/HeaderSkeleton.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
import ImagePreview from "@/commons/ImagePreview.vue";

export default {
  name: "MessengerCurrentChat",
  components: {
    PerfectScrollbar,
    FeatherIcon,
    Spinner,
    HeaderSkeleton,
    ChangeSms,
    ImagePreview,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      chat_type: [
        { id: "VIDEO", text: "Instagram video" },
        { id: "IMAGE", text: "Instagram image" },
        { id: "CAROUSEL_ALBUM", text: "Instagram carousel album" },
      ],
      showImageViewer: false,
      viewerImages: [],
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_INSTAGRAM_COMMENT_CHAT:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_COMMENT_CHAT",
      G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR",
    }),
  },
  methods: {
    handleScrollCaption(isExpanded) {
      if (isExpanded) {
        this.$refs.scrollCaption.$el.scrollTop = 0;
      }
    },
    showImage(data, index) {
      this.viewerImages = [];

      if (Array.isArray(data)) {
        let imageData = [...data];
        const [selectedImage] = imageData.splice(index, 1);
        this.viewerImages.push(selectedImage.media_url);
        imageData.forEach((item) => {
          this.viewerImages.push(item.media_url);
        });
      } else {
        this.viewerImages.push(data.media_url);
      }
      this.showImageViewer = true;
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    async handleScroll() {},

    getChatName(media_type) {
      return this.chat_type.find((chat) => chat.id === media_type).text;
    },
  },
};
</script>

<style lang="scss" >
.ig-current-chat-container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  background: #fff;
  border-left: 1px solid #eeeeee;
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  flex-direction: column;

  .custom-caption-scrollbar {
    height: 30vh;
    overflow-y: auto;
    padding-right: 1rem;

    .ps-container {
      height: 100%;
      position: relative;
    }
    .ps__thumb-y {
      background-color: var(--primary-color) !important;
    }
  }

  .custom-messages-scrollbar {
    margin-top: 0.2rem;
    height: 75vh;
    overflow-y: scroll;
    padding: 1rem;

    .ps-container {
      height: 100%;
      position: relative;
    }
    .ps__thumb-y {
      background-color: var(--primary-color) !important;
    }

    .instagram-message {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem;

      .heart-icon {
        cursor: pointer;

        &:hover {
          color: #ff0000;
        }
      }

      .comment-header {
        display: flex;
        flex-direction: row;

        span:nth-of-type(1) {
          font-weight: 900;
          margin-right: 1rem;
        }
      }

      .comment-stats {
        color: #8e8e8e;
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;
        justify-content: start;

        span:not(:last-child) {
          margin-right: 1rem;
        }
      }

      .comment-reply {
        margin: 1rem 0rem 0 3rem;
        cursor: pointer;
        .line {
          cursor: pointer;
          min-height: 1px;
          border-bottom: 1px solid #8e8e8e;
        }
        .view-reply-text {
          user-select: none;
          color: #8e8e8e;
          cursor: pointer;
          margin-top: 0.5rem;
          font-size: 0.9rem;
        }
        span:nth-of-type(2) {
          margin-left: 0.5rem;
          font-weight: 900;
        }
      }
    }
  }

  .header {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;
    flex-basis: 100%;
    width: 100% !important;
    border-bottom: 1px solid #eeeeee;
    font-size: large;
    max-height: 13%;

    .header-avatar {
      display: inline-block;
      margin-right: 1rem;
    }

    .header-text-bold {
      font-weight: 900;
    }

    .header-info {
      display: flex;
      flex-direction: column;
    }

    .header-stats {
      display: flex;
      flex-direction: row;

      span {
        font-size: 0.9rem;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
      }

      .hyphen-spacing {
        margin: 0 0.5rem;
      }
    }
  }
}

.dark-layout {
  .ig-current-chat-container {
    background: #111113;
    border-left: 1px solid #313131;

    .header {
      border-bottom: 1px solid #313131;
    }
  }
}
</style>