import { render, staticRenderFns } from "./ModalFilters.vue?vue&type=template&id=450e4a8d&scoped=true"
import script from "./ModalFilters.vue?vue&type=script&lang=js"
export * from "./ModalFilters.vue?vue&type=script&lang=js"
import style0 from "./ModalFilters.vue?vue&type=style&index=0&id=450e4a8d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450e4a8d",
  null
  
)

export default component.exports