<template>
  <div>
    <b-modal
      ref="modal-meta-filters"
      @hidden="$emit('hidden')"
      size="payment"
      header-class="custom-modal-header"
      content-class="custom-modal-content"
      modal-class="modal-meta-filters"
      no-enforce-focus
      centered
      no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <div>Advanced Search</div>
        <feather-icon
          icon="XCircleIcon"
          class="close-icon cursor-pointer"
          size="23"
          @click="close()"
        />
      </template>
      <template #default>
        <b-row>
          <b-col cols="12">
            <b-form-group
              class="lead-input"
              id="lead"
              label="Category"
              label-for="lead"
            >
              <div class="mt-1">
                <span
                  v-for="item in categories"
                  :key="item.value"
                  class="chat-classif"
                  :class="category == item.value ? 'bg-primary' : ''"
                  @click="category = item.value"
                >
                  {{ item.label }}
                </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="lead-input"
              id="class"
              label="Classification"
              label-for="class"
            >
              <div class="mt-1">
                <span
                  v-for="item in classifications"
                  :key="item.value"
                  class="chat-classif"
                  :class="classification == item.value ? 'bg-primary' : ''"
                  @click="classification = item.value"
                >
                  {{ item.label }}
                </span>
              </div>
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group
              class="lead-input"
              id="amount-input"
              label="Amount"
              label-for="amount-input"
            >
              <money
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$ ',
                  precision: 2,
                }"
                class="amount-input"
              />
            </b-form-group>
          </b-col> -->
          <b-col cols="6">
            <b-form-group
              class="lead-input"
              id="to-input"
              label="From (LM):"
              label-for="to-input"
            >
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                v-model="fromDate"
                locale="en"
                placeholder="MM/DD/YYYY"
                class="date-input"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="lead-input"
              label="To (LM): "
              id="from-input"
              label-for="from-input"
            >
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                v-model="toDate"
                locale="en"
                placeholder="MM/DD/YYYY"
                class="date-input"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer="">
        <b-button
          variant="outline-secondary"
          class="rounded"
          @click="changeState('reset')"
        >
          Reset
        </b-button>
        <b-button
          variant="primary"
          class="rounded button-collect"
          @click="changeState('search')"
        >
          Search
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      G_MESSENGER_META_FILTERS_ADVANCED:
        "MessengerMetaStore/G_MESSENGER_META_FILTERS_ADVANCED",
    }),
  },
  data() {
    return {
      categories: [
        { label: "New", value: "New" },
        { label: "Lead", value: "Lead" },
      ],
      classifications: [
        { label: "NO REACTION", value: "NO REACTION" },
        { label: "PENDING", value: "PENDING" },
        { label: "POTENTIAL", value: "POTENTIAL" },
        { label: "BLOCKED", value: "BLOCKED" },
      ],
      fromDate: null,
      toDate: null,
      classification: null,
      category: null,
    };
  },
  mounted() {
    this.initStatus();
    this.toggleModal("modal-meta-filters");
  },
  methods: {
    ...mapMutations({
      M_SET_MESSENGER_META_FILTERS_ADVANCED:
        "MessengerMetaStore/M_SET_MESSENGER_META_FILTERS_ADVANCED",
    }),

    initStatus() {
      this.fromDate = this.G_MESSENGER_META_FILTERS_ADVANCED.fromDate;
      this.toDate = this.G_MESSENGER_META_FILTERS_ADVANCED.toDate;
      this.classification =
        this.G_MESSENGER_META_FILTERS_ADVANCED.classification;
      this.category = this.G_MESSENGER_META_FILTERS_ADVANCED.category;
    },

    changeState(state) {
      if (state == "search") {
        this.M_SET_MESSENGER_META_FILTERS_ADVANCED({
          fromDate: this.fromDate,
          toDate: this.toDate,
          classification: this.classification,
          category: this.category,
        });
      } else {
        this.M_SET_MESSENGER_META_FILTERS_ADVANCED({
          fromDate: null,
          toDate: null,
          classification: null,
        });
      }

      this.$emit("hidden");
    },
  },
};
</script>
<style lang="scss">
.modal-meta-filters {
  .custom-modal-header {
    background: #fff !important;
    color: #000;
    padding: 1.9rem 1.4rem 0.8rem 1.4rem;
    border-radius: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close-icon {
      color: #82868b;
    }
  }
  .custom-modal-content {
    border-radius: 20px;
  }
  .lead-input {
    label {
      font-size: 1rem;
      font-weight: 700;
      color: #111113;
    }

    .choose-type-container {
      display: flex;
      gap: 10px;
      .choose-type-card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
        align-items: center;
        background: #f3f3f7;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 10px;
        font-size: 1rem;
        color: #111113;
        text-align: center;
        font-weight: 600;
        width: 100%;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        &.active {
          padding: 16px 8px;
          border: 2px solid #0090e7;
        }
      }
    }

    .chat-classif {
      background: #f3f3f7;
      padding: 10px;
      border-radius: 5px;
      font-size: 1rem;
      color: #111113;
      font-weight: 600;
      margin-right: 5px;
      cursor: pointer;
    }

    .pay-type-select {
      background: #f3f3f7;
      border-radius: 5px;
      border: none;
      .vs__dropdown-toggle {
        padding: 4px;
        border: none;
        .vs__selected-options {
          .vs__selected {
            color: #111113;
            font-weight: 600;
          }
        }
      }
    }
    .amount-input,
    .date-input {
      background: #f3f3f7;
      border-radius: 5px;
      border: none;
      color: #111113;
      font-weight: 600;
      &:focus-visible {
        outline: none;
      }
      &.border-danger {
        padding: 9px 12px;
      }
    }

    .amount-input {
      padding: 10px 13px;
    }
  }
  .payment-card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    .payment-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f3f3f7;
      padding: 10px 13px;
      border-radius: 5px;
      font-size: 1.1rem;
      color: #111113;
      font-weight: 600;
      .payment-card-customer-information {
        display: flex;
        flex-direction: column;
        .cu-name {
          font-size: 1rem;
          color: #111113;
          font-weight: 700;
        }
        .cu-card {
          font-size: 1rem;
          color: #82868b;
          font-weight: 600;
        }
      }
      &.active {
        padding: 8px 11px;
        border: 2px solid #0090e7;
      }
    }
  }
  .button-collect {
    font-weight: 700;
  }
}

.dark-layout {
  .modal-meta-filters {
    .custom-modal-header {
      background: #17171a !important;
      color: #fff;
      .close-icon {
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .lead-input {
      label {
        color: #fff;
      }

      .choose-type-container {
        .choose-type-card {
          background: #323241;
          color: #fff;
        }
      }

      .chat-classif {
        background: #323241;
        color: #fff;
      }

      .pay-type-select {
        background: #323241;
        .vs__dropdown-toggle {
          background: #323241;
          .vs__selected-options {
            .vs__selected {
              color: #fff;
            }
          }
        }
      }
      .amount-input,
      .date-input {
        background: #323241;
        color: #fff;
      }
    }
    .payment-card-container {
      .payment-card {
        background: #323241;
        color: #f3f3f7;
        .payment-card-customer-information {
          .cu-name {
            color: #f3f3f7;
          }
          .cu-card {
            color: #82868b;
          }
        }
      }
    }
  }
}
</style>