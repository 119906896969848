<template>
  <div class="d-flex align-items-start justify-content-start flex-column">
    <span class="ml-1"> <strong>LABELS</strong> </span>

    <b-badge
      class="d-block m-1"
      variant="light-primary"
      v-for="(custom_label, index) in custom_labels"
      :key="index"
    >
      {{ custom_label.page_label_name }}
    </b-badge>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR",
    }),

    custom_labels() {
      return this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.custom_labels;
    },
  },
};
</script>