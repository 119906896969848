<template>
  <div
    v-if="G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR === null"
    class="ig-comment-sidebar d-flex justify-content-center align-items-center"
  >
    <Spinner v-if="isLoading" :centerHorizontal="true" :centerVertical="true" />

    <span v-else> No results found.</span>
  </div>

  <div v-else class="ig-comment-sidebar">
    <Spinner v-if="isLoading" :centerHorizontal="true" :centerVertical="true" />

    <perfect-scrollbar
      v-else
      ref="scrollIgCommentsSidebar"
      @scroll="handleScroll"
    >
      <div
        v-for="chat in chats"
        :key="chat.id"
        class="ig-comment-list-item"
        @click="
          G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.id !== chat.id &&
            handleSelectChat(chat)
        "
        :class="{
          active: G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR.id === chat.id,
          isNew: chat.isNew,
        }"
      >
        <div class="chat-avatar">
          <b-avatar
            v-if="chat.media_type === 'VIDEO'"
            button
            size="3.5rem"
            :src="chat.thumbnail_url"
          ></b-avatar>
          <b-avatar
            v-else
            button
            size="3.5rem"
            :src="chat.media_url"
          ></b-avatar>
        </div>

        <div class="chat-content">
          <div class="chat-header">
            <span>{{ getChatName(chat.media_type) | limitChars(40) }}</span>
            <span>{{ chat.last_timestamp }}</span>
          </div>

          <div
            class="chat-message"
            v-b-tooltip.hover
            :title="chat.last_message.length > 20 ? chat.last_message : ''"
          >
            <span>{{ chat.last_message | limitChars(40) }}</span>
          </div>
        </div>
      </div>
    </perfect-scrollbar>

    <transition name="fade">
      <div class="is-loading-more" v-if="isLoadingMore">
        <span class="mr-1"> Loading more</span>
        <div class="dot-container">
          <div class="dot" v-for="n in 3" :key="n"></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MessengerMetaService from "@/views/commons/Messenger/components/services/messenger.meta.service.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Spinner from "@/views/commons/Messenger/components/messenger/messages/Spinner.vue";
import PerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: { Spinner, PerfectScrollbar },
  computed: {
    ...mapGetters({
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR",
      G_SEARCH_TEXT: "MessengerMetaStore/G_SEARCH_TEXT",
    }),
  },
  data() {
    return {
      params: {
        limit: 10,
        orderBy: "timestamp",
        order: "desc",
        offset: 0,
        exists_count: 0, // This is the total count of the comments
      },
      debounceTimeout: null,
      isLoading: true,
      chats: [],
      isLoadingMore: false,
      chat_type: [
        { id: "VIDEO", text: "Instagram video" },
        { id: "IMAGE", text: "Instagram image" },
        { id: "CAROUSEL_ALBUM", text: "Instagram carousel album" },
      ],
    };
  },
  created() {
    this.getInstagramSidebarComments();
  },
  methods: {
    ...mapActions({
      A_SET_CURRENT_INSTAGRAM_COMMENT_CHAT:
        "MessengerMetaStore/A_SET_CURRENT_INSTAGRAM_COMMENT_CHAT",
      A_SET_CURRENT_INSTAGRAM_COMMENT_SIDEBAR:
        "MessengerMetaStore/A_SET_CURRENT_INSTAGRAM_COMMENT_SIDEBAR",
    }),
    ...mapMutations({
      M_SET_CURRENT_INSTAGRAM_COMMENT_SIDEBAR:
        "MessengerMetaStore/M_SET_CURRENT_INSTAGRAM_COMMENT_SIDEBAR",
    }),
    getChatName(media_type) {
      return this.chat_type.find((chat) => chat.id === media_type).text;
    },
    resetParams() {
      this.params = {
        limit: 10,
        orderBy: "timestamp",
        order: "desc",
        offset: 0,
        exists_count: 0,
      };
    },
    async getInstagramSidebarComments() {
      try {
        const { data } = await MessengerMetaService.getInstagramSidebarComments(
          {
            page_id: this.G_CURRENT_FB_PAGE.id,
            params: this.params,
            search: this.G_SEARCH_TEXT,
          }
        );

        if (!data.posts || data.posts.length === 0) {
          return;
        }

        this.params.exists_count = data.exists_count;

        if (this.params.offset === 0) {
          this.handleSelectChat(data.posts[0]);
          this.chats = data.posts;
        } else {
          // add class to show as new
          data.posts.forEach((post) => {
            post.isNew = true;
          });

          this.chats = [...this.chats, ...data.posts];
          this.removeIsNew();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoading = false;
        this.isLoadingMore = false;
      }
    },
    removeIsNew() {
      // after 3 seconds remove is New
      setTimeout(() => {
        this.chats.forEach((chat) => {
          chat.isNew = false;
        });
      }, 3000);
    },
    handleSelectChat(chat) {
      this.A_SET_CURRENT_INSTAGRAM_COMMENT_SIDEBAR(chat);
      this.A_SET_CURRENT_INSTAGRAM_COMMENT_CHAT(chat.id);
    },
    handleScroll() {
      // Clear the previous timer if it exists
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      // Set a new timer
      this.debounceTimeout = setTimeout(() => {
        if (this.params.exists_count === this.chats.length) {
          return;
        }
        const container = this.$refs.scrollIgCommentsSidebar.$el;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight - 50) {
          this.params.offset += 10;
          this.getInstagramSidebarComments();
          this.isLoadingMore = true;
        }
      }, 200); // Debounce delay (200ms)
    },
  },
  watch: {
    G_CURRENT_FB_PAGE(newVal, oldVal) {
      if (newVal !== null && oldVal !== null && newVal.id !== oldVal.id) {
        this.resetParams();
        this.isLoading = true;
        this.getInstagramSidebarComments();
      }
    },
    G_SEARCH_TEXT() {
      this.resetParams();
      this.isLoading = true;
      this.M_SET_CURRENT_INSTAGRAM_COMMENT_SIDEBAR(null);
      this.getInstagramSidebarComments();
    },
  },
};
</script>

<style lang="scss" scoped>
.ig-comment-sidebar {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .ps-container {
    height: 100%;
    position: relative;
    .ps__thumb-y {
      background-color: var(--primary-color);
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    .ig-comment-list-item {
      display: flex;
      justify-content: start;
      padding: 1rem;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      cursor: pointer;

      &:hover {
        background-color: #0099dd11;
      }

      &.active {
        border-left: 4px solid #0099dd;
        background-color: #0099dd11;
      }

      &.isNew {
        background-color: #0099dd11;
      }

      .chat-avatar {
        display: inline-block;
        width: 15%;
        max-width: 15%;
      }

      .chat-content {
        display: flex;
        flex-direction: column;
        width: 85%;
      }

      .chat-header {
        display: flex;
        justify-content: space-between;

        span {
          font-size: 0.9rem;
          font-weight: 500;
          font-family: "Montserrat", sans-serif;
        }
      }

      .chat-message {
        user-select: none;
        margin-top: 0.5rem;
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          font-size: 0.9rem;
          font-weight: 400;
          font-family: "Montserrat", sans-serif;
        }
      }
    }
  }
}
.dark-layout {
  .ig-comment-sidebar {
    .is-loading-more {
      background-color: #22272b;
    }
  }
}
</style>