<template>
  <div class="default-responses-list">
    <div class="answer-header">
      <div class="title">Saved answers</div>
      <div class="button-add">
        <a href="#" role="link" @click.stop="$emit('addNewAnswer')"> + Add </a>
      </div>
    </div>
    <div class="answer-searchbar">
      <b-form-input
        v-model="search"
        id="searchbar-input"
        type="text"
        placeholder="Search..."
      />
    </div>
    <div class="answer-content">
      <perfect-scrollbar v-if="data.length > 0">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="answer-item"
          @click="addAnswer(item.message)"
        >
          <div>
            <div class="shortcut-method">{{ item.shortcutMethod }}</div>
            <div class="answer-message">{{ item.message }}</div>
          </div>
          <div class="actions">
            <b-dropdown
              class="dropdown-answer"
              size="xs"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              dropleft
            >
              <template #button-content>
                <feather-icon
                  class="cursor-pointer"
                  icon="MoreHorizontalIcon"
                />
              </template>
              <b-dropdown-item @click.stop="edit(item)">Edit</b-dropdown-item>
              <b-dropdown-item @click.stop="remove(item._id)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </perfect-scrollbar>
      <div
        v-if="data.length === 0 && !loading"
        class="d-flex justify-content-center align-items-center h-100"
      >
        No results found
      </div>
      <Spinner v-if="loading" :centerVertical="true" />
    </div>
  </div>
</template>
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import PredefinedAnswersMetaService from "@/views/commons/Messenger/components/messenger/predefined-answers/services/predefined-answers.service.js";
import Spinner from "@/views/commons/Messenger/components/messenger/messages/Spinner.vue";

export default {
  name: "PredefinedAnswersList",
  components: {
    PerfectScrollbar,
    Spinner,
  },
  data() {
    return {
      search: "",
      data: [],
      loading: false,
    };
  },
  created() {
    this.index();
  },
  methods: {
    async index() {
      try {
        this.loading = true;
        const params = {
          searchText: this.search,
        };
        const { data } = await PredefinedAnswersMetaService.index(params);
        this.data = [...data];
      } catch {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
    addAnswer(message) {
      this.$emit("addAnswer", message);
    },
    async remove(id) {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure you want to delete this answer?"
        );
        if (!isConfirmed) return;
        await PredefinedAnswersMetaService.delete(id);
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Answer deleted successfully"
        );
      } catch {
        this.showErrorSwal(error);
      }
    },
    edit(item) {
      this.$emit("editAnswer", item);
    },
  },
  watch: {
    search() {
      this.index();
    },
  },
};
</script>
<style lang="scss">
.default-responses-list {
  .answer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 0.5rem;
    .title {
      font-size: 1.2rem;
      font-weight: bold;
    }
    .button-add {
      font-size: 1rem;
      font-weight: bold;
      &:hover {
        a {
          text-decoration: underline;
        }
      }
    }
  }
  .answer-searchbar {
    margin-bottom: 1rem;
    #searchbar-input {
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="18px" stroke="%23ffffff" height="18px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg>')
        no-repeat;
      padding: 0.5rem 0rem 0.5rem 3rem;
      background-position: 1rem;
      font-size: 1rem;
      height: 2.5rem;
    }
  }
  .answer-content {
    height: 26.5rem;
    overflow: hidden;
    .ps-container {
      height: 100%;
      position: relative;
      .ps__thumb-y {
        background-color: var(--primary-color);
      }
    }
    .answer-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      font-size: 1rem;
      border-radius: 0.5rem;
      cursor: pointer;
      &:hover {
        background-color: #0099dd11;
      }
      .shortcut-method {
        font-weight: bold;
      }
      .dropdown-answer {
        .btn.dropdown-toggle {
          padding: 0.5rem !important;
          &:hover {
            background-color: #0099dd11;
          }
        }
        ul.dropdown-menu {
          margin: 0 !important;
          padding: 0 !important;
          min-width: 5rem !important;
          li {
            .dropdown-item {
              padding: 0.6rem;
            }
          }
        }
      }
    }
  }
}
</style>
