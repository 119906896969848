<template>
  <div class="message-ad-item">
    <div class="image-item">
      <b-img
        @click="getAdPreview(ad)"
        :src="ad.route_thumb"
        fluid
        thumbnail
        width="130"
        class="cursor-pointer"
      ></b-img>

      <div class="d-flex flex-column ml-1">
        <span v-if="ad.campaign_name" class="text-primary">
          {{ ad.campaign_name | limitChars(40) }}
        </span>
        <span v-if="ad.name">
          {{ ad.name | limitChars(40) }}
        </span>
        <span
          class="text-secondary"
          v-if="currentNestAd && currentNestAd.createdTime"
        >
          {{ formatDate(currentNestAd.createdTime) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: {
    ad: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR",
    }),

    currentNestAd() {
      return this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.referral.find(
        (ad) => ad.ad_id == this.ad.ad_id
      );
    },
  },
  methods: {
    getAdPreview(ad) {
      this.$emit("getAdPreview", ad);
    },

    formatDate(dateString) {
      const now = moment();
      const inputDate = moment(dateString);

      const differenceInMinutes = now.diff(inputDate, "minutes");
      const differenceInHours = now.diff(inputDate, "hours");

      if (differenceInMinutes < 60) {
        return `${differenceInMinutes}m ago`;
      } else if (differenceInHours < 24) {
        return `${differenceInHours}h ago`;
      } else {
        return inputDate.format("ddd MMM DD, YYYY h:mma");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-ad-item {
  padding: 1rem;
  width: 100%;
  .image-item {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>