<template>
  <div class="drag-and-drop-message">
    <div class="title">Upload an attachment</div>
    <drag-and-drop
      v-model="files"
      ref="dragAndDrop"
      :filesAcepted="filesAcepted"
      :files-array="files"
      :single="true"
    />
    <div class="actions">
      <b-button
        variant="outline-secondary"
        size="sm"
        @click.stop="$emit('cancelUpload')"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        @click.stop="sendAttachments()"
        :disabled="files.length === 0"
      >
        Send
      </b-button>
    </div>
  </div>
</template>
<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";

export default {
  name: "UploadAttachment",
  components: {
    DragAndDrop,
  },
  data() {
    return {
      files: [],
      filesAcepted: `image/png, image/jpeg, image/gif, image/webp, image/jpg,
        video/mp4, 
        audio/mpeg,
        application/pdf, application/msword, application/vnd.ms-excel, 
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, 
        application/vnd.openxmlformats-officedocument.presentationml.presentation, text/csv`,
      maxFileSize: 5 * 1024 * 1024, // 5MB
    };
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },

    validateFile(files) {
      const file = files[0];
      const handleValidationError = (message) => {
        this.showToast("warning", "top-right", "Oops!", "InfoIcon", message);
      };

      if (!this.filesAcepted.includes(file.type)) {
        const message = `File type not allowed`;
        handleValidationError(message);
        this.files = [];
        return;
      }

      if (file.size > this.maxFileSize) {
        const message = `File size exceeds the limit of ${
          this.maxFileSize / 1024 / 1024
        }MB`;
        handleValidationError(message);
        this.files = [];
        return;
      }
    },

    sendAttachments() {
      this.$emit("sendAttachments", this.files[0]);
    },
  },
  watch: {
    files: {
      handler(files) {
        if (files.length === 0) return;
        this.validateFile(files);
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.files = [];
  },
};
</script>
<style lang="scss">
.drag-and-drop-message {
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  width: 25rem;
  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
}

.dark-layout {
  .drag-and-drop-message {
    background-color: #17171a;
  }
}
</style>
