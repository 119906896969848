 <template>
  <div class="mx-2">
    <filter-slot
      :total-rows="totalRowsSaved"
      :paginate="paginate"
      :start-page="startPageSaved"
      :to-page="toPageSaved"
      :filter="[]"
      :filter-principal="filterPrincipalSaved"
      @reload="$refs['chatClassTable'].refresh()"
    >
      <b-table
        :ref="`chatClassTable`"
        slot="table"
        no-provider-filtering
        :items="myProvider"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy.sync="isBusy"
        :tbody-tr-class="rowClass"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(lead)="data">
          <div>
            <span>
              {{ data.item.sender.name }}
            </span>
          </div>
        </template>

        <template #cell(page)="data">
          <div>
            <b-avatar
              size="32"
              variant="light-secondary"
              class="cursor-pointer"
              :src="findProgram(data.item.pageId).picture"
            >
            </b-avatar>

            {{ findProgram(data.item.pageId).name }}
          </div>
        </template>

        <template #cell(chat)="data">
          <div>
            <feather-icon
              v-b-tooltip.hover
              title="Show chat"
              icon="EyeIcon"
              class="text-primary cursor-pointer"
              size="20"
              @click="openDetailChat(data)"
            />
          </div>
        </template>

        <template #cell(classification)="data">
          <div class="d-flex">
            <span v-if="!data.item.enableEdit">{{
              data.item.classification
            }}</span>

            <feather-icon
              icon="Edit2Icon"
              class="ml-1 text-warning cursor-pointer"
              v-b-tooltip.hover
              title="Edit classification"
              size="20"
              v-if="!data.item.enableEdit"
              @click="
                (data.item.lastClassification = data.item.classification),
                  (data.item.enableEdit = true)
              "
            />

            <v-select
              style="min-width: 200px"
              v-if="data.item.enableEdit"
              v-model="data.item.classification"
              :options="classificationOptions"
              :reduce="(option) => option.id"
              label="text"
              :clearable="false"
            />

            <feather-icon
              icon="SaveIcon"
              class="ml-1 text-primary cursor-pointer"
              v-b-tooltip.hover
              title="Save"
              v-if="data.item.enableEdit"
              size="20"
              @click="updateItem(data.item)"
            />
            <feather-icon
              icon="XSquareIcon"
              class="text-info cursor-pointer"
              v-b-tooltip.hover
              title="Cancel"
              v-if="data.item.enableEdit"
              size="20"
              @click="
                (data.item.classification = data.item.lastClassification),
                  (data.item.enableEdit = false)
              "
            />
          </div>
        </template>
      </b-table>
    </filter-slot>

    <ShowModalCurrentChat v-if="showModal" @close="showModal = false">
      <template #update>
        <div class="d-flex ml-2 align-items-center mycccccc">
          <v-select
            style="min-width: 200px; font-size: 15px !important"
            :options="classificationOptions"
            v-model="G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.classification"
            :reduce="(option) => option.id"
            label="text"
            :clearable="false"
          />

          <feather-icon
            icon="SaveIcon"
            class="ml-1 text-warning cursor-pointer"
            v-b-tooltip.hover
            title="Save"
            size="20"
            @click="
              updateItem({
                id: G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.id,
                classification:
                  G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.classification,
              })
            "
          />
        </div>
      </template>
    </ShowModalCurrentChat>
  </div>
</template>
  
<script>
import MetaService from "@/views/commons/Messenger/components/services/messenger.meta.service.js";
import MetaChastsService from "@/views/commons/Messenger/components/messenger/services/messenger.meta.service.js";
import ShowModalCurrentChat from "@/views/commons/Messenger/sentiment-analysis/components/modals/ModalMessages.vue";

import { mapGetters, mapActions } from "vuex";
import NestNotification from "@/socket/nest.notification.js";
export default {
  components: {
    ShowModalCurrentChat,
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_FB_PAGES: "MessengerMetaStore/G_FB_PAGES",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR",
    }),

    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },

  data() {
    return {
      showModal: false,
      socket: null,

      alterClassification: null,

      classificationOptions: [
        { id: "NO REACTION", text: "NO REACTION" },
        { id: "PENDING", text: "PENDING" },
        { id: "POTENTIAL", text: "POTENTIAL" },
        { id: "BLOCKED", text: "BLOCKED" },
        { id: "LOAN", text: "LOAN" },
        { id: "SLIP", text: "SLIP" },
        { id: "UNINTERESTED", text: "UNINTERESTED" },
        { id: "EMPLOYMENT", text: "EMPLOYMENT" },
        { id: "OTHER SERVICES", text: "OTHER SERVICES" },
      ],

      filterPrincipalSaved: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Name",
        model: "",
      },
      totalRowsSaved: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPageSaved: 0,
      toPageSaved: 0,

      isBusy: false,
      items: [],

      fields: [
        {
          key: "lead",
          label: "lead",
          visible: true,
        },
        {
          key: "page",
          label: "page",
          visible: true,
        },
        {
          key: "chat",
          label: "chat",
          visible: true,
        },

        {
          key: "classification",
          label: "Classification",
          visible: true,
        },

        // {
        //   key: "message_error",
        //   label: "detail",
        //   thStyle: {
        //     width: "30%",
        //   },
        //   visible: true,
        // },
      ],
    };
  },

  methods: {
    ...mapActions({
      A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR",
    }),

    async myProvider() {
      try {
        this.addPreloader();
        const params = {
          pageId: this.G_CURRENT_FB_PAGE.id,
          filterId: "no",
          perpage: this.paginate.perPage * 1,
          npage: this.paginate.currentPage * 1,
          searchText: this.filterPrincipalSaved.model,
        };

        const { data } = await MetaService.getPendingClassifications(params);
        this.startPageSaved = data.from ? data.from : 0;
        this.totalRowsSaved = data.total;
        this.toPageSaved = data.to ? data.to : 0;

        this.items = await (data.data ?? []).map((item) => {
          return {
            ...item,
            enableEdit: false,
            lastClassification: null,
          };
        });

        return this.items;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    findProgram(pageId) {
      const page = this.G_FB_PAGES.find((page) => page.id == pageId);
      if (page) {
        return page;
      }
      return {
        picture: null,
        name: "Not found",
      };
    },

    async updateItem(item) {
      if (item.classification == "" || !item.classification) {
        this.showInfoSwal("Please select a classification");
        return;
      }

      const confirmed = await this.showConfirmSwal();
      if (!confirmed.isConfirmed) return;

      const params = {
        classification: item.classification,
      };

      await MetaChastsService.updateMessengerChat(item.id, params);
      this.showSuccessSwal();
      this.showModal = false;
    },

    openDetailChat(data) {
      data.item.chatId = data.item.id;
      const auxItem = { ...data.item };
      this.A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR(auxItem);

      this.showModal = true;
    },

    updateChatFromSocket(payload) {
      const { id, classification } = payload;
      const existIndex = this.items.findIndex((chat) => chat.id == id);
      if (existIndex !== -1) {
        this.items[existIndex].classification = classification;
        this.items[existIndex].enableEdit = false;
      }
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.classification === "NO REACTION") return "table-warning";
    },

    socketOn() {
      this.socket.on("update-metachat", (data) => {
        this.updateChatFromSocket(data);
      });
    },

    initWebsocket() {
      this.socket = NestNotification.initWebsocket();
    },
  },

  watch:{
    G_CURRENT_FB_PAGE(){
      this.paginate.currentPage = 1;
      this.$refs['chatClassTable'].refresh()
    }
  },

  async created() {
    this.initWebsocket();
  },

  mounted() {
    this.socketOn();
    // this.getChats();
  },

  beforeDestroy() {
    this.socket.disconnect();
  },

};
</script>


<style lang="scss">
.mycccccc {
  .vs__selected {
    font-size: 15px !important;
  }
}
</style>