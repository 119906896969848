<template>
  <div class="h-100">
    <div class="chat-filters">
      <div
        class="chat-filter-item"
        v-for="(item, index) in G_META_LIST_FILTERS"
        :key="index"
        @click="changeFilter(item)"
        :class="
          G_CURRENT_META_LIST_FILTER.id == item.id
            ? 'is-active border-primary text-primary'
            : ''
        "
      >
        <span>{{ item.name }}</span>
      </div>
    </div>

    <div
      class="chat-list-container"
      style="max-height: 95%; overflow-y: auto"
      @scroll="loadMoreContacts"
    >
      <div class="messenger-chats">
        <div
          v-for="(chat, index) in G_CURRENT_LISTS_ITEMS"
          :key="index"
          class="chat-item py-1 pl-1 pr-0"
          :class="{
            active: G_CURRENT_META_LIST && G_CURRENT_META_LIST._id == chat._id,
          }"
          @click="selectChat(chat)"
        >
          <div class="w-100 item-content">
            <b-row class="w-100">
              <b-col cols="10">
                <div class="item-info w-100 text-truncate">
                  <strong>{{ chat.name }}</strong>
                </div>

                <div v-if="chat.lastMessage">
                  <div>
                    <div class="last-message w-100 text-truncate">
                      {{ chat.lastMessage.text }}
                    </div>
                  </div>
                </div>
                <div v-else class="text-muted">Conversation not started</div>
              </b-col>
              <b-col cols="2" style="padding: 0 !important">
                <div class="w-100">
                  <div style="text-align: right">
                    {{
                      chat.lastMessage
                        ? chat.lastMessage.sentDate
                        : chat.createdBy.moment | myGlobal
                    }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div
        class="text-center border-top pt-1"
        v-if="G_CURRENT_LISTS_ITEMS.length == 0"
      >
        No results found.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ListMetaService from "@/views/commons/Messenger/components/lists/services/lists.service.js";
import PerfectScrollbar from "vue-perfect-scrollbar";
export default {
  components: {
    PerfectScrollbar,
  },

  data() {
    return {
      loadingMessages: false,
      showModalCreateList: false,
      searchMore: true,
      page: 1,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      G_META_LIST_FILTERS: "MessengerMetaStore/G_META_LIST_FILTERS",
      G_CURRENT_META_LIST_FILTER:
        "MessengerMetaStore/G_CURRENT_META_LIST_FILTER",
      G_CURRENT_META_LIST: "MessengerMetaStore/G_CURRENT_META_LIST",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_CURRENT_LISTS_ITEMS: "MessengerMetaStore/G_CURRENT_LISTS_ITEMS",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  methods: {
    ...mapMutations({
      M_SET_CURRENT_META_LIST_FILTER:
        "MessengerMetaStore/M_SET_CURRENT_META_LIST_FILTER",
      M_SET_CURRENT_META_LIST: "MessengerMetaStore/M_SET_CURRENT_META_LIST",
      M_SET_CURRENT_LISTS_ITEMS: "MessengerMetaStore/M_SET_CURRENT_LISTS_ITEMS",
    }),

    changeFilter(item) {
      this.M_SET_CURRENT_META_LIST_FILTER(item);
    },

    selectChat(chat) {
      this.M_SET_CURRENT_META_LIST(chat);
    },

    loadMoreContacts() {
      const contactList = this.$el.querySelector(".chat-list-container");
      if (
        contactList.scrollTop +
          contactList.clientHeight +
          contactList.scrollHeight * 0.02 >=
          contactList.scrollHeight &&
        this.searchMore
      ) {
        this.getLists();
      }
    },

    openModalCreateChat() {
      this.showModalCreateList = true;
    },

    resetAll() {
      this.searchMore = true;
      this.page = 1;
      this.M_SET_CURRENT_LISTS_ITEMS([]);
      this.M_SET_CURRENT_META_LIST(null);
      this.getLists();
    },

    async getLists() {
      try {
        if (this.loading || !this.searchMore) return;

        this.loading = true;
        this.addPreloader();

        const payload = {
          pageId: this.G_CURRENT_FB_PAGE._id,
          tabFilter: this.G_CURRENT_META_LIST_FILTER.id,
          page: this.page,
        };

        this.page++;

        const { data } = await ListMetaService.getMetaList(payload);

        if (data.length == 0) {
          this.searchMore = false;
        }
        const auxArray = [...this.G_CURRENT_LISTS_ITEMS];
        const newContacts = [...auxArray, ...data];

        this.M_SET_CURRENT_LISTS_ITEMS(newContacts);
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.loading = false;
        this.removePreloader();
      }
    },
  },

  watch: {
    G_CURRENT_FB_PAGE(value) {
      if (!value) return;
      this.resetAll();
    },

    G_CURRENT_META_LIST_FILTER(value) {
      if (!value) return;
      this.resetAll();
    },
  },

  mounted() {
    this.getLists();
  },

  beforeDestroy() {
    this.M_SET_CURRENT_LISTS_ITEMS([]);
    this.M_SET_CURRENT_META_LIST(null);
  },
};
</script>