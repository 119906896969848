<template>
  <b-modal v-model="showModal" @hidden="$emit('hidden')" hide-footer>
    <template #modal-title v-if="listName">
      <span class="title-bold"> {{ listName }}</span>
    </template>

    <b-overlay variant="transparent" :show="false">
      <b-container fluid>
        <b-row class="mt-1">
          <b-col :cols="12">
            <b-input-group v-for="(value, index) in filters" :key="index">
              <template #prepend>
                <b-input-group-text class="prepend__width_number"
                  >{{ index + 1 }}.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text">{{
                value.label
              }}</b-input-group-text>
              <b-form-input
                :value="value.value_alias"
                class="prepend__width_value"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      required: true,
    },

    listName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showModal: true,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap");

.title-bold {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}
.prepend__width_number {
  justify-content: center;
  width: 3rem;
  border-radius: 0px 0px 0px 0px;
}
.prepend__width_text {
  justify-content: left;
  width: 16rem;
  border-radius: 0px 0px 0px 0px;
  border-right: none !important;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #99989a !important;
}
.prepend__width_value {
  color: #99989a !important;
  border-radius: 0px 0px 0px 0px !important;
  pointer-events: none;
  justify-content: right;
  text-align: right;
}
</style>