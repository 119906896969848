<template>
  <div>
    <div
      class="messenger"
      v-if="
        G_CURRENT_MESSENGER_META_TAB &&
        G_CURRENT_MESSENGER_META_TAB.id != 'classification' &&
        !isLoading
      "
    >
      <div class="messenger-list">
        <MessengerList
          v-if="G_FB_PAGES.length > 0 && G_CURRENT_MESSENGER_META_FILTER"
        />
      </div>
      <div
        class="messenger-current-chat"
        v-if="G_CURRENT_MESSENGER_META_TAB.id !== 'lists'"
      >
        <MessengerCurrentChat
          v-if="G_CURRENT_MESSENGER_META_TAB.id == 'messenger'"
        />

        <InstagramCurrentChat
          v-if="G_CURRENT_MESSENGER_META_TAB.id == 'instagram'"
        />

        <FacebookPostCommentCurrentChat
          v-if="G_CURRENT_MESSENGER_META_TAB.id == 'facebook_comments'"
        />

        <InstagramCommentCurrentChat
          v-if="G_CURRENT_MESSENGER_META_TAB.id == 'instagram_comments'"
        />
      </div>

      <div
        class="messenger-current-chat"
        v-if="G_CURRENT_MESSENGER_META_TAB.id == 'lists'"
      >
        <MetaListContent v-if="G_CURRENT_META_LIST" />
      </div>
    </div>
    <div v-if="G_CURRENT_MESSENGER_META_TAB.id == 'classification'">
      <MessengerList
        v-if="G_FB_PAGES.length > 0 && G_CURRENT_MESSENGER_META_FILTER"
      />
    </div>
    <div
      v-if="isLoading"
      class="messenger d-flex justify-content-center align-items-center"
    >
      <div class="gif-container">
        <img :src="gifSrc" />
      </div>
    </div>
  </div>
</template>
<script>
import MessengerList from "@/views/commons/Messenger/components/MessengerList.vue";
import MessengerCurrentChat from "@/views/commons/Messenger/components/messenger/MessengerCurrentChat.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import InstagramCommentCurrentChat from "@/views/commons/Messenger/components/instagram/InstagramCommentCurrentChat.vue";
import MetaListContent from "@/views/commons/Messenger/components/lists/MetaListContent.vue";
import InstagramCurrentChat from "@/views/commons/Messenger/components/instagram/InstagramCurrentChat.vue";
import FacebookPostCommentCurrentChat from "@/views/commons/Messenger/components/facebook/FacebookPostCommentCurrentChat.vue";
export default {
  name: "Messenger",
  components: {
    MessengerList,
    MessengerCurrentChat,
    InstagramCommentCurrentChat,
    MetaListContent,
    InstagramCurrentChat,
    FacebookPostCommentCurrentChat,
  },
  data() {
    return {
      gifSrc: require("@/assets/images/pages/meta_loader.gif"),
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      G_FB_PAGES: "MessengerMetaStore/G_FB_PAGES",
      G_CURRENT_MESSENGER_META_TAB:
        "MessengerMetaStore/G_CURRENT_MESSENGER_META_TAB",
      G_CURRENT_MESSENGER_META_FILTER:
        "MessengerMetaStore/G_CURRENT_MESSENGER_META_FILTER",
      G_CURRENT_META_LIST: "MessengerMetaStore/G_CURRENT_META_LIST",
    }),
  },

  methods: {
    ...mapActions({
      A_SET_CURRENT_MESSENGER_META_TAB:
        "MessengerMetaStore/A_SET_CURRENT_MESSENGER_META_TAB",
      A_SET_CURRENT_MESSENGER_META_FILTER:
        "MessengerMetaStore/A_SET_CURRENT_MESSENGER_META_FILTER",
      A_GET_USERS_IMAGES: "SmsInboxStore/A_GET_USERS_IMAGES",
    }),
    ...mapMutations({
      M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT:
        "MessengerMetaStore/M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT",
      M_SET_CURRENT_FB_COMMENT_CHAT:
        "MessengerMetaStore/M_SET_CURRENT_FB_COMMENT_CHAT",
    }),
  },
  watch: {
    G_CURRENT_MESSENGER_META_TAB(oldVal, newVal) {
      if (oldVal !== null && newVal !== null) {
        // reset current chats on change tab
        this.M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT(null);
        this.M_SET_CURRENT_FB_COMMENT_CHAT(null);
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.A_SET_CURRENT_MESSENGER_META_TAB("messenger");
    this.A_SET_CURRENT_MESSENGER_META_FILTER("all");
    this.A_GET_USERS_IMAGES(15);
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  },
};
</script>
<style lang="scss" scoped>
.messenger {
  background: #fafafa;
  height: 86vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 8px 0px #dbdbdb;
  overflow: hidden;

  .messenger-list {
    height: 100%;
    flex-basis: 50%;
    width: 50%;
    padding: 0;
    margin: 0;
  }

  .messenger-current-chat {
    height: 100%;
    flex-basis: 50%;
    width: 50%;
    padding: 0;
    margin: 0;
  }

  @media (min-width: 800px) {
    .messenger-list {
      flex-basis: 40%;
      width: 40%;
    }
    .messenger-current-chat {
      flex-basis: 60%;
      width: 60%;
    }
  }

  @media (min-width: 1300px) {
    .messenger-list {
      flex-basis: 30%;
      width: 30%;
    }
    .messenger-current-chat {
      flex-basis: 70%;
      width: 70%;
    }
  }
}

.dark-layout {
  .messenger {
    background: #111113;
    border: 2px solid #17171a;
    box-shadow: none;
  }
  .gif-container {
    position: relative;
    display: inline-block;
  }

  .gif-container img {
    display: block;
    filter: invert(1);
    mix-blend-mode: screen;
  }
}
</style>
