<template>
  <div class="">
<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 19H7C4.23858 19 2 16.7614 2 14V7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V14C22 16.7614 19.7614 19 17 19H12.586L9.293 22.293C9.11201 22.474 8.848 22.5308 8.6216 22.4319C8.39519 22.333 8.25 22.1054 8.25 21.85V19.75C8.25 19.3358 8.58579 19 9 19Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.5 7H13C12.4477 7 12 7.4477 12 8V10H11V12H12V17H14V12H15L15.5 10H14V8.5C14 8.2239 14.2239 8 14.5 8H15.5V7H14.5Z" fill="currentColor"/>
</svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>

<style lang="scss" scoped> 
</style>