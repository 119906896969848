export default [
    {
        key: "lead",
        label: "lead",
        visible: true,
    },


    {
        key: "status_queue",
        label: "status",
        visible: true,
    },

    {
        key: "message_error",
        label: "detail",
        thStyle: {
            width: "30%",
        },
        visible: true,
    },

    {
        key: "created_at",
        label: "created at",
        visible: true,
    },

    {
        key: "sent_date",
        label: "sent date",
        visible: true,
    },

];
