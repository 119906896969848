<template>
  <div
    class="fb-comment-sidebar d-flex justify-content-center align-items-center"
    v-if="G_CURRENT_FB_COMMENT_SIDEBAR === null"
  >
    <Spinner v-if="isLoading" :centerHorizontal="true" :centerVertical="true" />
    <span v-else>No results found.</span>
  </div>

  <div class="fb-comment-sidebar" v-else>
    <Spinner v-if="isLoading" :centerHorizontal="true" :centerVertical="true" />

    <perfect-scrollbar
      v-else
      ref="scrollFbCommentsSidebar"
      @scroll="handleScroll"
    >
      <div
        v-for="chat in chats"
        :key="chat.id"
        class="fb-comment-list-item"
        @click="
          G_CURRENT_FB_COMMENT_SIDEBAR.id !== chat.id && handleSelectChat(chat)
        "
        :class="{
          active: G_CURRENT_FB_COMMENT_SIDEBAR.id === chat.id,
          isNew: chat.isNew,
        }"
      >
        <div class="chat-avatar">
          <b-avatar
            button
            size="60"
            :src="
              chat.full_picture != null
                ? chat.full_picture
                : G_CURRENT_FB_PAGE.picture
            "
          ></b-avatar>
        </div>

        <div class="chat-content">
          <div class="chat-header">
            <span v-if="chat.message">{{ chat.message | limitChars(40) }}</span>
            <span v-else-if="chat.story">{{
              chat.story | limitChars(40)
            }}</span>
            <span v-else>{{
              (G_CURRENT_FB_PAGE.name + " post") | limitChars(40)
            }}</span>
          </div>

          <div
            class="chat-message"
            v-b-tooltip.hover
            :title="chat.last_message.length > 20 ? chat.last_message : ''"
          >
            <span class="justify-content-end">{{
              chat.last_message | limitChars(40)
            }}</span>
          </div>
        </div>

        <span class="chat-date">{{ chat.updated_time_formatted }}</span>
      </div>
    </perfect-scrollbar>

    <LoadingMore v-if="isLoadingMore" />
  </div>
</template>

<script>
import MessengerMetaService from "@/views/commons/Messenger/components/services/messenger.meta.service.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Spinner from "@/views/commons/Messenger/components/messenger/messages/Spinner.vue";
import PerfectScrollbar from "vue-perfect-scrollbar";
import LoadingMore from "@/views/commons/Messenger/components/LoadingMore.vue";

export default {
  components: { Spinner, PerfectScrollbar, LoadingMore },
  computed: {
    ...mapGetters({
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_CURRENT_FB_COMMENT_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_FB_COMMENT_SIDEBAR",
      G_SEARCH_TEXT: "MessengerMetaStore/G_SEARCH_TEXT",
    }),
  },
  data() {
    return {
      chats: [],
      isLoading: true,
      isLoadingMore: false,
      params: {
        limit: 10,
        orderBy: "updated_time",
        order: "desc",
        offset: 0,
        exists_count: 0, // This is the total count of the comments
      },
      debounceTimeout: null,
    };
  },
  created() {
    this.getFacebookSidebarComments();
  },
  beforeDestroy() {
    // Clear timeout if component is destroyed
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  },
  methods: {
    ...mapActions({
      A_SET_CURRENT_FB_COMMENT_CHAT:
        "MessengerMetaStore/A_SET_CURRENT_FB_COMMENT_CHAT",
      A_SET_CURRENT_FB_COMMENT_SIDEBAR:
        "MessengerMetaStore/A_SET_CURRENT_FB_COMMENT_SIDEBAR",
    }),
    ...mapMutations({
      M_SET_CURRENT_FB_COMMENT_SIDEBAR:
        "MessengerMetaStore/M_SET_CURRENT_FB_COMMENT_SIDEBAR",
    }),
    resetParams() {
      this.params = {
        limit: 10,
        orderBy: "updated_time",
        order: "desc",
        offset: 0,
        exists_count: 0,
      };
    },
    async getFacebookSidebarComments() {
      try {
        const { data } = await MessengerMetaService.getFacebookSidebarComments({
          page_id: this.G_CURRENT_FB_PAGE.id,
          params: this.params,
          search: this.G_SEARCH_TEXT,
        });

        if (!data.posts || data.posts.length === 0) {
          console.log("No data found");
          return;
        }

        this.params.exists_count = data.exists_count;

        if (this.params.offset === 0) {
          this.handleSelectChat(data.posts[0]);
          this.chats = data.posts;
        } else {
          // add class to show as new
          data.posts.forEach((post) => {
            post.isNew = true;
          });

          this.chats = [...this.chats, ...data.posts];
          this.removeIsNew();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoading = false;
        this.isLoadingMore = false;
      }
    },
    removeIsNew() {
      // after 3 seconds remove is New
      setTimeout(() => {
        this.chats.forEach((chat) => {
          chat.isNew = false;
        });
      }, 3000);
    },
    handleSelectChat(chat) {
      this.A_SET_CURRENT_FB_COMMENT_SIDEBAR(chat);
      this.A_SET_CURRENT_FB_COMMENT_CHAT(chat.id);
    },
    handleScroll() {
      // Clear the previous timer if it exists
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      // Set a new timer
      this.debounceTimeout = setTimeout(() => {
        if (this.params.exists_count === this.chats.length) {
          return;
        }
        const container = this.$refs.scrollFbCommentsSidebar.$el;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight - 50) {
          this.params.offset += 10;
          this.getFacebookSidebarComments();
          this.isLoadingMore = true;
        }
      }, 200); // Debounce delay (200ms)
    },
  },
  watch: {
    G_CURRENT_FB_PAGE(newVal, oldVal) {
      if (newVal !== null && oldVal !== null && newVal.id !== oldVal.id) {
        this.resetParams();
        this.isLoading = true;
        this.getFacebookSidebarComments();
      }
    },
    G_SEARCH_TEXT() {
      this.resetParams();
      this.isLoading = true;
      this.M_SET_CURRENT_FB_COMMENT_SIDEBAR(null);
      this.getFacebookSidebarComments();
    },
  },
};
</script>

<style lang="scss" scoped>
.fb-comment-sidebar {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .is-loading-more {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 0;
    padding: 0.2rem;
    transform: translateX(-50%);
    background-color: #f8f8f8;

    .dot-container {
      display: flex;
      justify-content: center;
      padding: 1rem;

      .dot {
        width: 8px;
        height: 8px;
        margin: 0 4px;
        background-color: var(--primary-color);
        border-radius: 50%;
        animation: dot-blink 1.5s infinite;
      }

      .dot:nth-child(1) {
        animation-delay: 0s;
      }
      .dot:nth-child(2) {
        animation-delay: 0.3s;
      }
      .dot:nth-child(3) {
        animation-delay: 0.6s;
      }
    }

    @keyframes dot-blink {
      0%,
      80%,
      100% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
    }
  }

  .ps-container {
    height: 100%;
    position: relative;
    .ps__thumb-y {
      background-color: var(--primary-color);
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    .fb-comment-list-item {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1.3rem;
      cursor: pointer;

      &:hover {
        background-color: #0099dd11;
      }

      &.active {
        border-left: 4px solid #0099dd;
        background-color: #0099dd11;
      }

      &.isNew {
        background-color: #0099dd11;
      }

      .chat-avatar {
        margin-right: 1rem;
      }

      .chat-content {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .chat-header {
        display: flex;
        justify-content: space-between;

        span {
          font-size: 0.9rem;
          font-weight: 500;
          font-family: "Montserrat", sans-serif;
        }
      }

      .chat-message {
        user-select: none;
        margin-top: 0.5rem;
        max-width: calc(100% - 120px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          font-size: 0.9rem;
          font-weight: 400;
          font-family: "Montserrat", sans-serif;
        }
      }

      .chat-date {
        font-size: 0.8rem;
      }
    }
  }
}

.dark-layout {
  .fb-comment-sidebar {
    .is-loading-more {
      background-color: #22272b;
    }
  }
}
</style>