<template>
  <div class="h-100 w-100">
    <div class="header-ls">
      <div class="d-flex flex-column">
        <div class="d-flex">
          <div class="d-flex flex-column justify-content-center">
            <div
              class="font-weight-bolder"
              v-if="renameActive"
              style="padding-left: 4px"
            >
              {{ G_CURRENT_META_LIST.name }}
            </div>
            <b-form-input
              style="
                padding: 0;
                padding-left: 4px;
                height: 2rem;
                min-width: 200px;
              "
              v-else
              v-model="listName"
            />
          </div>
          <div
            class="d-flex align-items-center"
            v-if="isCeo && !G_CURRENT_META_LIST.deletedBy"
          >
            <feather-icon
              v-if="renameActive"
              icon="Edit2Icon"
              class="ml-1 text-warning cursor-pointer"
              v-b-tooltip.hover
              title="Rename"
              size="20"
              @click="renameActive = false"
            />
            <feather-icon
              v-if="!renameActive"
              icon="SaveIcon"
              class="ml-1 text-primary cursor-pointer"
              v-b-tooltip.hover
              title="Save"
              size="20"
              @click="updateList()"
            />
            <feather-icon
              v-if="!renameActive"
              icon="XSquareIcon"
              class="text-info cursor-pointer"
              v-b-tooltip.hover
              title="Cancel"
              size="20"
              @click="renameActive = true"
            />
            <feather-icon
              icon="UserPlusIcon"
              class="ml-1 text-warning cursor-pointer"
              v-b-tooltip.hover
              title="Add Chats"
              @click="showModalAddLeads = true"
              size="20"
            />
            <feather-icon
              icon="UsersIcon"
              class="ml-1 text-primary cursor-pointer"
              v-b-tooltip.hover
              title="Chats"
              @click="showModalLeadsDetail = true"
              size="20"
            />
          </div>
        </div>
        <div>
          <span>({{ G_CURRENT_META_LIST.countLeads }}) Chats</span>
        </div>
      </div>

      <div class="container-icons pr-2">
        <div
          class="d-flex align-items-center p-0 m-0"
          style="height: auto !important"
        >
          <feather-icon
            v-if="G_CURRENT_META_LIST.filters"
            v-b-tooltip.hover
            title="SHOW FILTERS"
            @click="showModalFilters = true"
            icon="FilterIcon"
            size="25"
            class="cursor-pointer text-primary"
          />

          <feather-icon
            class="cursor-pointer text-danger ml-1"
            icon="Trash2Icon"
            size="25"
            v-b-tooltip.hover
            title="DELETE"
            v-if="!G_CURRENT_META_LIST.deletedBy"
            @click="deleteList()"
          />
        </div>
      </div>
    </div>
    <div class="content-ls pb-1" ref="chatContainer" @scroll="loadMoreMessages">
      <!-- <perfect-scrollbar v-if="messagesList.length > 0" ref="scrollContainer"> -->
      <div
        v-for="(message, index) in messagesList"
        class="d-flex flex-column align-items-center"
        :key="`message_mm_ls_${index}`"
      >
        <div
          :id="`message_${message._id}`"
          class="message mt-1 d-flex justify-content-center"
          style="max-width: 80%"
        >
          <div class="d-flex align-items-center mr-1">
            <feather-icon
              icon="TrashIcon"
              class="text-warning cursor-pointer"
              style="margin-right: 5px"
              size="18"
              v-b-tooltip.hover
              title="Delete"
              v-if="
                message.status == 'Pending' &&
                !message.deletedBy &&
                !G_CURRENT_META_LIST.deletedBy
              "
              @click="deleteMessage(message._id)"
            />
            <feather-icon
              v-if="
                message.status == 'Pending' &&
                !message.deletedBy &&
                !G_CURRENT_META_LIST.deletedBy
              "
              icon="CalendarIcon"
              v-b-tooltip.hover
              :title="message.status"
              class="text-info"
              size="18"
            />
            <feather-icon
              v-if="
                message.status == 'InProgress' &&
                !message.deletedBy &&
                !G_CURRENT_META_LIST.deletedBy
              "
              icon="LoaderIcon"
              v-b-tooltip.hover
              :title="message.status"
              class="text-warning"
              size="18"
            />
            <feather-icon
              v-if="
                message.status == 'Failed' &&
                !message.deletedBy &&
                !G_CURRENT_META_LIST.deletedBy
              "
              icon="InfoIcon"
              v-b-tooltip.hover
              title="Failed"
              class="text-warning"
              size="18"
            />
            <feather-icon
              v-if="message.status == 'Completed'"
              icon="SendIcon"
              v-b-tooltip.hover
              :title="`${message.status} - Open Detail`"
              class="text-success cursor-pointer"
              size="18"
              @click="openDetailMessage(message)"
            />
          </div>

          <div
            v-if="message._id"
            class="text-center"
            :style="message.deletedBy ? 'text-decoration: line-through' : ''"
          >
            <div
              style="margin: 0 !important; border-radius: 20px"
              class="bubble-message-list p-2"
            >
              {{ message.text }}
            </div>
          </div>
        </div>

        <div v-if="message._id" style="margin-top: 5px">
          <b-avatar
            variant="info"
            size="16"
            style="maring-left: 5px"
            v-b-tooltip.hover
            :src="getImageUser(message.createdBy.id)"
            :title="message.createdBy.name"
          />
          <span v-if="message.deletedBy">
            {{ message.deletedBy.moment | Iso2CADateWithHour }}
          </span>
          <span v-else>
            {{ message.sentDate | Iso2CADateWithHour }}
          </span>
        </div>
      </div>
      <!-- </perfect-scrollbar> -->
    </div>
    <div class="footer-ls" v-if="!G_CURRENT_META_LIST.deletedBy">
      <!-- <feather-icon
        icon="PaperclipIcon"
        class="mx-1 cursor-pointer mb-1"
        size="25"
        @click="activeSearchFiles()"
      ></feather-icon>

      <input
        id="inputFiles"
        type="file"
        ref="inputFiles"
        style="display: none"
      /> -->

      <div class="w-100">
        <b-form-textarea
          class="custom-form-input"
          placeholder="Type message ..."
          max-rows="3"
          v-model="newMessage"
          :state="
            newMessage != '' &&
            newMessage.length >= 1 &&
            newMessage.length <= 500
          "
        />
        <div class="d-flex justify-content-between" style="margin-top: 5px">
          <div>
            <b-row>
              <!-- <b-col sm="6">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text>@1</b-input-group-prepend>
                  <b-form-input placeholder="USERNAME" readonly />
                </b-input-group>
              </b-col> -->
            </b-row>
          </div>
          <div class="d-flex align-items-center h-100">
            <span>{{ newMessage ? newMessage.length : 0 }} / 500</span>
          </div>
        </div>
      </div>
      <feather-icon
        icon="SendIcon"
        size="25"
        class="mx-1 mb-1 cursor-pointer"
        @click="createNewMessage()"
      ></feather-icon>
    </div>
    <div
      v-else
      class="footer-ls d-flex justify-content-center"
      style="font-size: 16px"
    >
      Deleted by
      {{ G_CURRENT_META_LIST.deletedBy.name }} at
      {{ G_CURRENT_META_LIST.deletedBy.moment | Iso2CADateWithHour }}
    </div>

    <b-modal
      size="sm"
      v-model="showModalB"
      title="Message Schedule"
      centered
      hide-footer
      @hidden="(messageDate = null), (messageHour = null)"
    >
      <validation-observer ref="form">
        <b-row>
          <b-col cols="12">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-group label="Date: " label-for="date">
                <kendo-datepicker
                  id="date"
                  v-model="messageDate"
                  v-mask="'##/##/####'"
                  :first-day-of-week="1"
                  placeholder="MM/DD/YYYY"
                  :format="'MM/dd/yyyy'"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                  :min="new Date()"
                  class="w-100 rounded bg-transparent k-picker-custom"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-group label="Hour: " label-for="hour">
                <kendo-timepicker
                  v-model="messageHour"
                  :format="'HH:mm'"
                  class="w-100 rounded bg-transparent k-picker-custom"
                  style="height: 2.73rem"
                  :class="{ 'border-danger': errors[0] }"
                  placeholder="Select hour"
                  :interval="5"
                  :min="minTime"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>

      <div class="d-flex justify-content-end mt-1">
        <b-button variant="primary" @click="saveMessageAtt">
          <feather-icon icon="CalendarIcon"></feather-icon>
          Schedule
        </b-button>
      </div>
    </b-modal>

    <MetaListLeads
      v-if="showModalAddLeads"
      @hidden="showModalAddLeads = false"
    />

    <ModalFilters
      v-if="showModalFilters"
      @hidden="showModalFilters = false"
      :listName="`FILTERS OF LIST: ${G_CURRENT_META_LIST.name}`"
      :filters="G_CURRENT_META_LIST.filters ? G_CURRENT_META_LIST.filters : []"
    />

    <ModalDetail
      v-if="showModalDetail"
      @hidden="showModalDetail = false"
      :message="currentMessage"
    />

    <ModalLeadsDetail
      v-if="showModalLeadsDetail"
      @hidden="showModalLeadsDetail = false"
      :listId="G_CURRENT_META_LIST._id"
    />
  </div>
</template>

<script>
import PerfectScrollbar from "vue-perfect-scrollbar";

import { mapGetters, mapMutations } from "vuex";

import NestNotification from "@/socket/nest.notification.js";
import ListMetaService from "@/views/commons/Messenger/components/lists/services/lists.service.js";
import ListMessagesMetaService from "@/views/commons/Messenger/components/lists/services/messages.service.js";

import MetaListLeads from "@/views/commons/Messenger/components/lists/MetaListLeads.vue";
import ModalFilters from "@/views/commons/Messenger/components/lists/utils/ModalFilters.vue";
import ModalDetail from "@/views/commons/Messenger/components/lists/utils/ModalDetail.vue";
import ModalLeadsDetail from "@/views/commons/Messenger/components/lists/MetaListLeadsDetail.vue";

import moment from "moment-timezone";

export default {
  components: {
    PerfectScrollbar,
    MetaListLeads,
    ModalFilters,
    ModalDetail,
    ModalLeadsDetail,
  },
  data() {
    return {
      messagesList: [],
      newMessage: "",
      showModalFilters: false,
      showModalLeadsDetail: false,
      renameActive: true,
      listName: "",

      minTime: "",
      messageDate: null,
      messageHour: null,
      showModalB: false,
      loadingMessageScroll: false,
      moreMessage: true,
      page: 1,

      showModalAddLeads: false,
      showModalDetail: false,
      currentMessage: null,

      socket: null,
    };
  },

  computed: {
    ...mapGetters({
      G_CURRENT_META_LIST: "MessengerMetaStore/G_CURRENT_META_LIST",
      currentUser: "auth/currentUser",
      G_USERS_IMAGES: "SmsInboxStore/G_USERS_IMAGES",
    }),
  },

  methods: {
    ...mapMutations({
      M_SET_NEW_OR_UPDATE_LIST: "MessengerMetaStore/M_SET_NEW_OR_UPDATE_LIST",
    }),

    getImageUser(userId) {
      const user = this.G_USERS_IMAGES.find((user) => user.id == userId);
      if (user) {
        return user.image_thumb;
      }
      return null;
    },

    activeSearchFiles() {
      this.$refs["inputFiles"].click();
    },

    loadMoreMessages() {
      const listHistory = this.$refs.chatContainer;
      if (
        listHistory.scrollHeight -
          (Math.abs(listHistory.scrollTop) + listHistory.clientHeight) <=
          10 &&
        !this.loadingMessageScroll
      ) {
        this.getMessages();
      }
    },

    async deleteList() {
      const confirm = await this.showConfirmSwal();
      if (!confirm.value) return;
      try {
        const dateUTC = new Date();
        const isoDate = dateUTC.toISOString();

        const params = {
          deletedBy: {
            id: this.currentUser.user_id,
            name: this.currentUser.fullName,
            moment: isoDate,
          },
        };

        const { data } = await ListMetaService.updateList(
          this.G_CURRENT_META_LIST._id,
          params
        );

        this.M_SET_NEW_OR_UPDATE_LIST(data);
      } catch (e) {
        this.showErrorSwal();
      }
    },

    createNewMessage() {
      if (
        !this.newMessage ||
        this.newMessage == "" ||
        this.newMessage.length > 500
      )
        return;

      var date = new Date();
      var roundedMinutes = Math.ceil(date.getMinutes() / 5) * 5;
      date.setMinutes(roundedMinutes);
      date.setSeconds(0);
      date.setMilliseconds(0);

      if (date.getMinutes() === 60) {
        date.setMinutes(0);
        date.setHours(date.getHours() + 1);
      }

      const auxDate = new Date();
      if (date.getMinutes() - auxDate.getMinutes() < 3) {
        date.setMinutes(date.getMinutes() + 5);
      }

      var options = {
        timeZone: "America/Los_Angeles",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      var formatter = new Intl.DateTimeFormat("en-US", options);
      var caliTime = formatter.format(date);
      var dateString = date.toISOString().split("T")[0];
      var caliDateTimeString = `${dateString} ${caliTime}`;
      this.minTime = new Date(caliDateTimeString);

      this.showModalB = true;
    },

    async saveMessageAtt() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return false;

      try {
        this.addPreloader();
        const dateUTC = new Date();
        const isoDate = dateUTC.toISOString();
        // let withImage = null;

        // if (this.filesUpload.length > 0) {
        //   withImage = true;
        //   await Promise.all(
        //     this.filesUpload.map(async (file) => {
        //       file.base64 = await this.readFileAsync(file.file);
        //     })
        //   );
        // }

        const params = {
          listId: this.G_CURRENT_META_LIST._id,
          createdBy: {
            id: this.currentUser.user_id,
            name: this.currentUser.fullName,
            moment: isoDate,
          },
          sentDate: moment.tz(
            this.messageDate + " : " + this.messageHour,
            "MM/DD/YYYY : HH:mm",
            "America/Los_Angeles"
          ),
          text: this.newMessage,
        };

        const { data } = await ListMessagesMetaService.createListMessage(
          params
        );

        this.messageHour = null;
        this.messageDate = null;
        this.newMessage = "";
        this.showModalB = false;
        // this.M_SET_NEW_OR_UPDATE_LIST({
        //   _id: this.G_CURRENT_META_LIST._id,
        //   lastMessage: data,
        // });

        this.messagesList.unshift(data);

        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async updateList() {
      if (this.listName == "" || !this.listName) return;

      const confirm = await this.showConfirmSwal();
      if (!confirm.value) return;

      try {
        this.addPreloader();
        const params = {
          name: this.listName,
        };

        const { data, status } = await ListMetaService.updateList(
          this.G_CURRENT_META_LIST._id,
          params
        );

        if (status == 200) {
          this.M_SET_NEW_OR_UPDATE_LIST(data);
          this.showSuccessSwal();
          this.listName = "";
          this.renameActive = true;
        }
      } catch (e) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    async deleteMessage(messageId) {
      const confirm = await this.showConfirmSwal();
      if (!confirm.value) return;

      try {
        const dateUTC = new Date();
        const isoDate = dateUTC.toISOString();

        const params = {
          deletedBy: {
            id: this.currentUser.user_id,
            name: this.currentUser.fullName,
            moment: isoDate,
          },
        };

        const { data, status } =
          await ListMessagesMetaService.updateListMessage(messageId, params);

        if (status == 200) {
          this.updateItem(data);
        }
      } catch (e) {
        this.showErrorSwal();
      }
    },

    updateItem(item) {
      const index = this.messagesList.findIndex(
        (filter) => filter._id === item._id
      );
      if (index !== -1) {
        if (item.deletedBy) {
          this.messagesList[index].deletedBy = item.deletedBy;
        }

        if (item.status != "Pending") {
          this.messagesList[index].status = item.status;
        }
      }
    },

    async getMessages() {
      if (!this.moreMessage) return;

      try {
        this.loadingMessageScroll = true;

        const params = {
          listId: this.G_CURRENT_META_LIST._id,
          page: this.page,
          tabFilter: "active",
        };
        const { data } = await ListMessagesMetaService.getMetaListMessage(
          params
        );

        if (data.length == 0) {
          this.moreMessage = false;
        } else {
          const auxArray = [...this.messagesList];
          const newContacts = [...auxArray, ...data];
          this.messagesList = newContacts;
          this.page++;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingMessageScroll = false;
      }
    },

    openDetailMessage(message) {
      this.currentMessage = message;
      this.showModalDetail = true;
    },

    resetAll() {
      this.loadingMessageScroll = false;
      this.moreMessage = true;
      this.page = 1;
      this.messagesList = [];
      this.getMessages();
    },

    async activeSocketChangeStatus() {
      try {
        this.socket.on("notification-meta-list-update", (data) => {
          if (data.listId != this.G_CURRENT_META_LIST._id) return;

          this.updateItem({
            _id: data.messageId,
            status: data.status,
          });
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    initWebsocket() {
      this.socket = NestNotification.initWebsocket();
    },
  },

  watch: {
    G_CURRENT_META_LIST(value) {
      if (!value) return;
      this.resetAll();
    },
  },

  async created() {
    this.initWebsocket();
  },

  mounted() {
    this.getMessages();
    this.activeSocketChangeStatus();
  },

  async destroyed() {
    this.socket.disconnect();
  },
};
</script>

<style lang="scss">
.header-ls {
  height: 8%;
  width: 100%;
  display: flex;
  border-bottom: 1px solid black;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
}

.content-ls {
  height: 78%;
  overflow-y: scroll;

  flex-direction: column-reverse;
  display: flex;

  .bubble-message-list {
    color: #000;
    background-color: #efefef;
  }
}

.footer-ls {
  height: 14%;
  display: flex;
  align-items: center;
  border-top: 1px solid black;
}

.dark-layout {
  .content-ls {
    .bubble-message-list {
      background-color: #22272b !important;
      color: #fff;
    }
  }
}
</style>