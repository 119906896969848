import { nestApi } from "@/service/axios";

class PredefinedAnswersMetaService {
    async create(params) {
        try {
            const data = await nestApi.post('predefined-answers', params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async index(params) {
        try {
            const data = await nestApi.post('predefined-answers/index', params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async update(id, params) {
        try {
            const data = await nestApi.patch(`predefined-answers/${id}`, params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async delete(id) {
        try {
            const data = await nestApi.delete(`predefined-answers/${id}`);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new PredefinedAnswersMetaService();