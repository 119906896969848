<template>
  <div class="message-user-profile">
    <b-avatar
      size="55"
      variant="light-secondary"
      :src="G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sender.imageUrl"
    />
    <div class="ctx-ava">
      <div class="font-weight-bolder">
        <strong>{{
          G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sender.name
        }}</strong>
      </div>
      <b-dropdown
        id="dropdown-1"
        :text="G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.classification"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        v-if="
          !classificationsUpdate.includes(
            G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.classification
          )
        "
      >
        <template #button-content>
          <b-badge
            :variant="variantClassification"
            class="text-truncate"
            v-b-tooltip.hover
            title="Change"
          >
            {{ G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.classification }}
            <feather-icon icon="ChevronDownIcon" />
          </b-badge>
        </template>
        <b-dropdown-item
          v-for="item in classifications"
          :key="item.id"
          @click="updateItem(item.id)"
          >{{ item.text }}</b-dropdown-item
        >
      </b-dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MetaChastsService from "@/views/commons/Messenger/components/messenger/services/messenger.meta.service.js";

export default {
  name: "UserProfile",
  data() {
    return {
      classificationOptions: [
        { id: "POTENTIAL", text: "POTENTIAL" },
        { id: "BLOCKED", text: "BLOCKED" },
        { id: "LOAN", text: "LOAN" },
        { id: "SLIP", text: "SLIP" },
        { id: "UNINTERESTED", text: "UNINTERESTED" },
        { id: "EMPLOYMENT", text: "EMPLOYMENT" },
        { id: "OTHER SERVICES", text: "OTHER SERVICES" },
      ],
      classificationsUpdate: ["NO REACTION", "POTENTIAL", "BLOCKED"],
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR",
    }),

    classifications() {
      return this.classificationOptions.filter(
        (item) =>
          item.text !==
          this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.classification
      );
    },

    variantClassification() {
      const classi =
        this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.classification;
      switch (classi) {
        case "POTENTIAL":
          return "light-primary";
        case "PENDING":
          return "light-warning";
        case "BLOCKED":
          return "light-dark";
        default:
          return "light-secondary";
      }
    },
  },

  methods: {
    async updateItem(classification) {
      if (classification == "") {
        this.showInfoSwal("Please select a classification");
        return;
      }

      const confirmed = await this.showConfirmSwal();
      if (!confirmed.isConfirmed) return;

      const params = {
        classification,
      };

      await MetaChastsService.updateMessengerChat(
        this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.id,
        params
      );
      this.showSuccessSwal();
    },
  },
};
</script>
<style lang="scss">
.message-user-profile {
  display: flex;
  gap: 1rem;
  align-items: center;
  strong {
    font-size: 1.2rem;
  }

  .ctx-ava {
    .btn {
      padding: 0 !important;
    }
  }
}
</style>