<template>
  <div>
    <div class="scrollbar-ads">
      <span class="ml-1">
        <strong>
          ADS <span v-if="ads.length > 1">({{ ads.length }})</span>
        </strong>
      </span>

      <perfect-scrollbar ref="scrollAds" v-if="ads.length > 0">
        <MessageAdItem
          v-for="ad in ads"
          :ad="ad"
          :key="ad.ad_id"
          @getAdPreview="getAdPreview"
        />
      </perfect-scrollbar>
      <div v-if="(ads.length === 0) & !loading" class="text-center mt-3">
        <span class="text-muted">No ads found</span>
      </div>

      <b-row v-if="loading" no-gutters class="mt-1">
        <b-col cols="4" class="mx-1">
          <b-skeleton-img height="90px"></b-skeleton-img>
        </b-col>
        <b-col cols="7">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
        </b-col>
      </b-row>
    </div>

    <preview-ad
      v-if="showPreviewAd"
      :type="'ad_preview'"
      :ad-id="adId"
      :ad-name="adName"
      :meta-user-id="metaUserId"
      @hidden="showPreviewAd = false"
    />
  </div>
</template>

<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import PreviewAd from "@/views/creative/views/meta/components/PreviewAd.vue";
import MessageAdItem from "@/views/commons/Messenger/components/messenger/messages/MessageAdItem.vue";
import MetaService from "@/views/creative/views/meta/service/meta.service.js";

export default {
  components: {
    PerfectScrollbar,
    MessageAdItem,
    PreviewAd,
  },
  props: {
    adIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  created() {
    this.getAdsByIds();
  },
  data() {
    return {
      ads: [],
      showPreviewAd: false,
      adId: null,
      adName: null,
      metaUserId: null,
      loading: true,
    };
  },
  computed: {},
  methods: {
    async getAdsByIds() {
      try {
        this.loading = true;
        this.ads = [];

        const ads_ids = this.adIds;

        let params = {
          ads_ids,
        };

        let { data } = await MetaService.getAdsByIds(params);

        if (data.length === 0) {
          return;
        }
        const sortedData = data.sort((a, b) => {
          return ads_ids.indexOf(a.ad_id) - ads_ids.indexOf(b.ad_id);
        });
        this.ads = sortedData.reverse();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
    getAdPreview(ad) {
      this.showPreviewAd = true;
      this.adId = ad.ad_id;
      this.adName = ad.name;
      this.metaUserId = ad.meta_user_id;
    },
  },
};
</script>

<style lang="scss">
.scrollbar-ads {
  overflow: hidden;
  width: 100%;
  height: 170px;

  .ps-container {
    height: 100%;
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;
    .ps__thumb-y {
      background-color: var(--primary-color);
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }
}
</style>