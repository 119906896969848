<template>
  <div class="messenger-message-header">
    <div class="user-profile">
      <UserProfile />
      <b-badge
        v-if="G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment"
        :id="`sentiment-score-${G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.id}`"
        :variant="sentiment.variant"
        class="sentiment-score"
        :class="[hasSentimentHistory ? 'cursor-pointer' : '']"
        @click="openAnalysisHistory"
      >
        <component
          :is="sentiment.icon"
          class="icon-sentiment-score"
          :color="sentiment.color"
        />
        <b-tooltip
          v-if="
            G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment.sentimentScore
          "
          :target="`sentiment-score-${G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.id}`"
          triggers="hover"
          placement="top"
        >
          <div class="w-100 d-flex justify-content-start">
            <div class="d-flex flex-column">
              <span class="text-primary text-weight-bolder">
                · Neutral :
                {{
                  G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment
                    .sentimentScore.neutral
                }}
                %
              </span>
              <span class="text-success text-weight-bolder">
                · Positive :
                {{
                  G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment
                    .sentimentScore.positive
                }}
                %
              </span>
              <span class="text-danger text-weight-bolder">
                · Negative :
                {{
                  G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment
                    .sentimentScore.negative
                }}
                %
              </span>
              <span class="text-info text-weight-bolder">
                · Mixed :
                {{
                  G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment
                    .sentimentScore.mixed
                }}
                %
              </span>
            </div>
          </div>
        </b-tooltip>
        {{ sentiment.name }}
      </b-badge>
    </div>

    <div class="message-actions">
      <template v-if="!loading">
        <template v-if="!G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.leadId">
          <div v-if="leads.length > 0">
            <b-button
              variant="outline-warning"
              size="sm"
              @click="showLinkedLead = true"
            >
              <feather-icon icon="LinkIcon" size="16" />
              Link Lead
            </b-button>
          </div>
          <lead-create-social
            :color_btn="'outline-primary'"
            :btn-size="'sm'"
            :btn-text="'Lead'"
            :btn-icon-class="''"
            :btn-class="'d-flex justify-content-center align-items-center'"
            :meta-chat-data="metaChatData"
            @leadId="addLeadToChat($event)"
          />
        </template>
      </template>
      <template v-else>
        <b-spinner
          variant="primary"
          label="Loading..."
          class="text-primary"
        ></b-spinner>
      </template>
      <b-badge
        v-if="G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.leadId"
        variant="light-primary"
        class="d-flex justify-content-center align-items-center cursor-pointer"
        style="height: 1.5rem"
        v-b-tooltip.hover.top="`Open Lead Dashboard`"
        @click="openLeadDashboard()"
      >
        Lead
      </b-badge>

      <btn-create-appointment
        v-if="G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.leadId"
        :key="`app_${G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.leadId}`"
        :leadId="G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.leadId"
        :senderId="senderId"
        platform="messenger"
        from-metamedia
      />

      <!-- <feather-icon
        icon="AlertOctagonIcon"
        class="cursor-pointer"
        size="20"
        v-b-tooltip.hover.bottom="'Move to spam'"
      /> -->
      <feather-icon
        icon="SearchIcon"
        class="cursor-pointer"
        size="20"
        v-b-tooltip.hover.bottom="'Search messages'"
        @click="handleSidebar(1)"
      />
      <feather-icon
        icon="InfoIcon"
        class="text-primary cursor-pointer"
        size="20"
        v-b-tooltip.hover.bottom="'View contact'"
        @click="handleSidebar(2)"
      />
    </div>
    <Sidebar
      v-if="showSidebar"
      @close="showSidebar = false"
      ref="messenger-lead-sidebar"
    />
    <ModalAnalysisVersions
      v-if="showAnalysisHistory"
      @close="showAnalysisHistory = false"
      :analysisHistory="analysisHistory"
    />
    <LinkedLead
      v-if="showLinkedLead"
      :leads="leads"
      @close="showLinkedLead = false"
      @linked="addLeadToChat($event)"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import MessengerMetaService from "@/views/commons/Messenger/components/messenger/services/messenger.meta.service.js";
import Sidebar from "@/views/commons/Messenger/components/messenger/messages/SidebarMessages.vue";
import UserProfile from "@/views/commons/Messenger/components/messenger/messages/components/UserProfile.vue";
import LeadCreateSocial from "@/views/social-network/views/leads/components/lead-create/LeadCreateSocial.vue";
import PositiveIcon from "@/icons/PositiveIcon.vue";
import NeutralIcon from "@/icons/NeutralIcon.vue";
import MixedIcon from "@/icons/MixedIcon.vue";
import NegativeIcon from "@/icons/NegativeIcon.vue";
import ModalAnalysisVersions from "@/views/commons/Messenger/sentiment-analysis/components/modals/ModalAnalysisVersions.vue";
import BtnCreateAppointment from "@/views/commons/components/ring-central/chat-sms/components/utils/BtnCreateAppointment.vue";
import SNLeadsService from "@/views/social-network/services/leads";
import LinkedLead from "@/views/commons/Messenger/components/messenger/messages/components/LinkedLead.vue";

export default {
  name: "MessageHeader",
  components: {
    Sidebar,
    UserProfile,
    LeadCreateSocial,
    PositiveIcon,
    NeutralIcon,
    MixedIcon,
    NegativeIcon,
    ModalAnalysisVersions,
    BtnCreateAppointment,
    LinkedLead,
  },
  data() {
    return {
      showSidebar: false,
      metaChatData: {},
      showAnalysisHistory: false,
      analysisHistory: [],
      leads: [],
      loading: false,
      showLinkedLead: false,
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
    }),
    sentiment() {
      const sentiments = {
        Positive: {
          icon: PositiveIcon,
          color: "#28a745",
          variant: "light-success",
          name: "Positive",
        },
        Negative: {
          icon: NegativeIcon,
          color: "#dc3545",
          variant: "light-danger",
          name: "Negative",
        },
        Neutral: {
          icon: NeutralIcon,
          color: "#007bff",
          variant: "light-primary",
          name: "Neutral",
        },
        Mixed: {
          icon: MixedIcon,
          color: "#8f5fe8",
          variant: "light-info",
          name: "Mixed",
        },
      };
      let sentiment = null;
      if (
        this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR &&
        this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment
      ) {
        sentiment =
          this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment.sentiment;
      }
      return sentiments[sentiment] || sentiments.Neutral;
    },

    hasSentimentHistory() {
      return (
        this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR &&
        this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment &&
        this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment.sentimentHistory
          .length > 0
      );
    },
    senderId() {
      return this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sender.id;
    },
    senderName() {
      return this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sender.name;
    },
    cleanSenderName() {
      const emojiRegex =
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF])/g;
      return this.senderName.replace(emojiRegex, "").trim();
    },
  },
  created() {},
  mounted() {
    this.initMetaChatData();
  },
  methods: {
    ...mapActions({
      A_SET_CURRENT_MESSENGER_MESSAGE_SIDEBAR:
        "MessengerMetaStore/A_SET_CURRENT_MESSENGER_MESSAGE_SIDEBAR",
      A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR",
    }),
    handleSidebar(type) {
      this.showSidebar = true;
      this.A_SET_CURRENT_MESSENGER_MESSAGE_SIDEBAR({ type: type });
    },
    async addLeadToChat(leadId) {
      try {
        this.addPreloader();
        const params = { leadId: leadId };
        const chatId = this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.id;
        const { data } = await MessengerMetaService.updateMessengerChat(
          chatId,
          params
        );
        await this.A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR(data);
        this.showToast(
          "success",
          "top-right",
          "Lead successfully registered",
          "CheckIcon"
        );
      } catch (error) {
        this.showToast("danger", "top-right", error.message, "XIcon");
      } finally {
        this.removePreloader();
      }
    },
    initMetaChatData() {
      const referral = this.getReferralData();
      if (!this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.leadId) {
        this.findLeadByNickname();
      }
      this.metaChatData = {
        isFromChat: true,
        nickname: this.cleanSenderName,
        programId: this.getProgramIdByName(this.G_CURRENT_FB_PAGE.name),
        sourceId: 3,
        sub_source: referral.source,
        referral: {
          ad_id: referral.ad_id,
          ad_name: referral.ad_name,
        },
      };
    },
    getReferralData() {
      let data = {
        source: null,
        ad_id: null,
        ad_name: null,
      };
      const referral =
        this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR?.referral || [];

      if (referral.length === 0) return data;

      const lastReferral = referral[referral.length - 1];

      const sourceTypes = {
        ADS: 1,
        COMMENT: 2,
        RECOVERY: 4,
        // ... Other source types
      };

      if (lastReferral.source === "ADS") {
        data = {
          source: sourceTypes.ADS,
          ad_id: lastReferral.ad_id,
          ad_name: lastReferral?.ads_context_data?.ad_title || null,
        };
      }
      return data;
    },
    openLeadDashboard() {
      window.open(
        `/socialnetwork/leads/new/dashboard/${this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.leadId}`,
        "_blank"
      );
    },
    openAnalysisHistory() {
      this.analysisHistory =
        this.G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sentiment.sentimentHistory;
      this.showAnalysisHistory = true;
    },
    async findLeadByNickname() {
      const nickname = this.cleanSenderName;
      this.loading = true;
      this.leads = [];
      try {
        const data = await SNLeadsService.findLeadByNickname({ nickname });
        this.leads = data;
      } catch (error) {
        this.showToast("danger", "top-right", error.message, "XIcon");
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR: {
      handler() {
        this.initMetaChatData();
      },
    },
  },
};
</script>
<style lang="scss">
.messenger-message-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  .user-profile {
    display: flex;
    gap: 1rem;
    align-items: center;
    .sentiment-score {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
      font-size: 1rem;
      .icon-sentiment-score {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }
  .message-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
}

.dark-layout {
  .messenger-message-header {
    background: #111113;
    border-bottom: 1px solid #313131;
  }
}
</style>