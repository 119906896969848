<template>
  <b-modal
    v-model="showModal"
    @hidden="$emit('hidden')"
    centered
    title="New List"
    hide-footer
    size="sm"
  >
    <validation-observer ref="form">
      <b-row class="mb-2">
        <b-col cols="12">
          <validation-provider v-slot="{ errors }" rules="required">
            <b-form-group label="Name:">
              <b-form-input
                id="name"
                v-model="listName"
                :class="errors[0] ? 'is-invalid' : ''"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>

    <div class="d-flex justify-content-end">
      <b-button variant="outline-secondary" @click="$emit('hidden')">
        Cancel
      </b-button>
      <b-button class="ml-1" variant="primary" @click="createChat">
        Create
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import ListMetaService from "@/views/commons/Messenger/components/lists/services/lists.service.js";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      showModal: true,
      listName: "",
    };
  },

  computed: {
    ...mapGetters({
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      currentUser: "auth/currentUser",
    }),
  },

  methods: {
    ...mapMutations({
      M_SET_NEW_OR_UPDATE_LIST: "MessengerMetaStore/M_SET_NEW_OR_UPDATE_LIST",
    }),

    async createChat() {
      let isValid = this.$refs["form"].validate();
      if (!isValid || this.listName == "" || !this.listName) return;

      this.addPreloader();

      try {
        const dateUTC = new Date();
        const isoDate = dateUTC.toISOString();

        const payload = {
          name: this.listName,
          pageId: this.G_CURRENT_FB_PAGE._id,
          createdBy: {
            id: this.currentUser.user_id,
            name: this.currentUser.fullName,
            moment: isoDate,
          },
        };

        const { data, status } = await ListMetaService.createList(payload);

        if (status === 201) {
          this.$emit("hidden");
          this.showSuccessSwal();
          this.M_SET_NEW_OR_UPDATE_LIST(data);
        }
      } catch (e) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>