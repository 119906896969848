<template>
  <div class="instagram-chats">
    <perfect-scrollbar
      v-if="INSTAGRAM_CONVERSATIONS.length > 0"
      ref="scrollContainer"
      @scroll="handleScroll"
    >
      <div
        v-for="(chat, index) in INSTAGRAM_CONVERSATIONS"
        :key="index"
        class="chat-item"
        :class="{ active: isActive === chat.id }"
        @click="selectedChat(chat)"
      >
        <div class="item-content" style="max-width: 80%">
          <div class="item-profile">
            <b-avatar
              size="60"
              variant="light-secondary"
              :src="chat.sender.imageUrl"
            />
            <img
              src="@/assets/images/icons/iconoInstagram.svg"
              class="icon-instagram"
              alt=""
            />
          </div>
          <div class="item-info">
            <div class="name text-truncate">
              <strong>{{ chat.sender.name }}</strong>
            </div>
            <div class="last-message text-truncate">
              <span :class="{ 'text-bold': chat.unreadCount }">
                {{ chat.lastMessage }}
              </span>
            </div>

            <div class="d-flex flex-nowrap">
              <b-badge
                :variant="getVariant(chat.category)"
                class="text-truncate"
                style="max-width: 50px"
              >
                {{ chat.category }}
              </b-badge>
            </div>
          </div>
        </div>
        <div class="item-date">
          <span class="message-date" :class="{ 'text-bold': chat.unreadCount }">
            {{ getMessageDay(chat) }}
          </span>
          <b-badge
            v-if="chat.unreadCount"
            variant="danger"
            class="unread-count"
          >
            {{ chat.unreadCount }}
          </b-badge>
        </div>
      </div>
    </perfect-scrollbar>
    <div
      v-else-if="INSTAGRAM_CONVERSATIONS.length === 0 && !loading"
      class="text-center my-2"
    >
      No results found.
    </div>
    <div v-else-if="loading" class="text-center text-primary my-2">
      <b-spinner class="align-middle mr-1" />
    </div>
    <LoadingMore v-if="previousChats" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import PerfectScrollbar from "vue-perfect-scrollbar";
import LoadingMore from "@/views/commons/Messenger/components/LoadingMore.vue";
import NestNotification from "@/socket/nest.notification.js";
import InstagramMetaService from "@/views/commons/Messenger/components/instagram/services/instagram.meta.service.js";

export default {
  name: "ChatList",
  components: {
    PerfectScrollbar,
    LoadingMore,
  },
  data() {
    return {
      INSTAGRAM_CONVERSATIONS: [],
      loading: false,
      isActive: null,
      perpage: 10,
      npage: 1,
      previousChats: false,
      hasMoreData: true,
      socket: null,
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_SEARCH_TEXT: "MessengerMetaStore/G_SEARCH_TEXT",
      G_CURRENT_MESSENGER_META_FILTER:
        "MessengerMetaStore/G_CURRENT_MESSENGER_META_FILTER",
      G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR",
      G_MESSENGER_META_FILTERS_ADVANCED:
        "MessengerMetaStore/G_MESSENGER_META_FILTERS_ADVANCED",
    }),
  },
  created() {
    this.socket = NestNotification.initWebsocket();
    this.hasMoreData = true;
    this.getChats();
  },
  mounted() {
    this.socketOn();
  },
  methods: {
    ...mapActions({
      A_SET_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/A_SET_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR",
    }),

    async getChats() {
      try {
        this.loading = true;
        let params = {
          searchText: this.G_SEARCH_TEXT,
          pageId: this.G_CURRENT_FB_PAGE.id,
          npage: this.npage,
          perpage: this.perpage,
          filterId: this.G_CURRENT_MESSENGER_META_FILTER.id,

          classification: this.G_MESSENGER_META_FILTERS_ADVANCED.classification,
          category: this.G_MESSENGER_META_FILTERS_ADVANCED.category,
          fromDate: this.G_MESSENGER_META_FILTERS_ADVANCED.fromDate,
        };

        if (this.G_MESSENGER_META_FILTERS_ADVANCED.toDate) {
          params.toDate =
            this.G_MESSENGER_META_FILTERS_ADVANCED.toDate + " : 23:59:59";
        }

        const { data } = await InstagramMetaService.getInstagramChats(params);

        if (!this.previousChats) {
          this.selectedChat(data[0]);
        }

        if (data.length === 0) {
          this.hasMoreData = false;
          return;
        }

        this.INSTAGRAM_CONVERSATIONS = this.previousChats
          ? [...this.INSTAGRAM_CONVERSATIONS, ...data]
          : data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
        this.previousChats = false;
      }
    },

    getMessageDay(chat) {
      const TIME_ZONE = "America/Los_Angeles";
      const date = moment(chat.lastMessageDate).tz(TIME_ZONE);
      const today = moment().tz(TIME_ZONE).startOf("day");
      const yesterday = moment()
        .tz(TIME_ZONE)
        .subtract(1, "days")
        .startOf("day");

      if (date.isSame(today, "day")) {
        return date.format("h:mm a");
      }

      if (date.isSame(yesterday, "day")) {
        return "yesterday";
      }

      return date.format("DD/MM/YY");
    },
    selectedChat(chat) {
      const chatId = chat?.id ?? null;
      if (this.isActive === chatId) return;
      this.isActive = chatId;
      this.A_SET_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR(chat ?? null);
    },
    handleScroll() {
      const container = this.$refs.scrollContainer.$el;
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        if (this.hasMoreData && !this.loading) {
          this.previousChats = true;
          this.npage += 1;
          this.getChats();
        }
      }
    },
    clearItems() {
      this.previousChats = false;
      this.npage = 1;
      this.loading = false;
      this.hasMoreData = true;
      this.INSTAGRAM_CONVERSATIONS = [];
      this.A_SET_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR(null);
      this.isActive = null;
    },
    socketOn() {
      this.socket.on("meta-instagram", (data) => {
        if (this.isCurrentFbPageMessage(data)) {
          const { chat, message } = data;
          if (message) {
            const { messageStatus, me } = message;
            const filterId = this.G_CURRENT_MESSENGER_META_FILTER.id;
            if (messageStatus === "Sent") {
              this.handleMessageSent(chat, me, filterId);
            }
            if (messageStatus === "Read") {
              this.handleMessageRead(chat);
            }
          } else if (chat.leadId) {
            this.handleMessageRead(chat);
          }
        }
      });
      //init socket update-metachat-instagram
    },
    isCurrentFbPageMessage(data) {
      return (
        data.chat &&
        data.chat.pageId &&
        data.chat.pageId === this.G_CURRENT_FB_PAGE.id
      );
    },
    handleMessageSent(chat, me, filterId) {
      const { sender } = chat;
      if (
        this.G_SEARCH_TEXT.trim() &&
        !sender.name.toLowerCase().includes(this.G_SEARCH_TEXT.toLowerCase())
      ) {
        return;
      }

      const index = this.INSTAGRAM_CONVERSATIONS.findIndex(
        (item) => item.id === chat.id
      );
      if (index !== -1) {
        this.INSTAGRAM_CONVERSATIONS.splice(index, 1);
      }
      if (filterId === "unread" && me) return;

      this.INSTAGRAM_CONVERSATIONS.unshift(chat);
    },
    handleMessageRead(chat) {
      this.INSTAGRAM_CONVERSATIONS = this.INSTAGRAM_CONVERSATIONS.map(
        (item) => {
          if (item.id === chat.id) {
            return { ...item, ...chat };
          }
          return item;
        }
      );
    },
    getVariant(category) {
      switch (category) {
        case "New":
          return "light-success";
        case "Lead":
          return "light-primary";
        default:
          return "light-success";
      }
    },
  },
  watch: {
    G_CURRENT_FB_PAGE() {
      this.clearItems();
      this.getChats();
    },
    G_SEARCH_TEXT() {
      this.clearItems();
      this.getChats();
    },
    G_CURRENT_MESSENGER_META_FILTER() {
      this.clearItems();
      this.getChats();
    },
    G_MESSENGER_META_FILTERS_ADVANCED() {
      this.clearItems();
      this.getChats();
    },
  },
  beforeDestroy() {
    this.clearItems();
    this.socket.disconnect();
  },
};
</script>
<style lang="scss">
.instagram-chats {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .chat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1.3rem;
    cursor: pointer;

    &:hover {
      background-color: #0099dd11;
    }

    &.active {
      border-left: 4px solid #0099dd;
      background-color: #0099dd11;
    }

    .item-content {
      display: flex;
      gap: 1rem;
      align-items: center;
      width: 70%;
      overflow: hidden;

      .item-profile {
        position: relative;
        .icon-instagram {
          position: absolute;
          width: 2rem;
          height: 2rem;
          bottom: -0.2rem;
          right: -0.5rem;
          border-radius: 50%;
          background: #fff;
          border: 4px solid #fff;
        }
      }
      .item-info {
        width: 70%;
        overflow: hidden;
      }
    }
    .item-date {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 30%;
      overflow: hidden;
      margin-right: 0.5rem;
      .message-date {
        font-size: 0.8rem;
        color: #9a9a9a;
      }
      .unread-count {
        border-radius: 50%;
      }
    }
    .text-bold {
      font-weight: bold;
      color: unset !important;
    }

    @media (min-width: 1540px) {
      .item-content {
        width: 85%;
        .item-info {
          width: 85%;
        }
      }

      .item-date {
        width: 15%;
      }
    }
  }
}

.dark-layout {
  .instagram-chats {
    .chat-item {
      .item-content {
        .item-profile {
          .icon-instagram {
            background: #101a21;
            border: 4px solid #101a21;
          }
        }
      }
    }
  }
}
</style>