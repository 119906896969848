<template>
  <b-sidebar
    id="sidebar-rigth-messages"
    :visible="showSidebar"
    right
    backdrop
    bg-variant="white"
    sidebar-class="sidebar-lg"
    header-class="pt-1"
    :no-close-on-backdrop="false"
    :no-close-on-esc="false"
    lazy
    @hidden="$emit('close')"
  >
    <template #header>
      <SidebarHeader @close="$emit('close')" />
    </template>
    <div>
      <SearchMessages
        v-if="G_CURRENT_MESSENGER_MESSAGE_SIDEBAR.type == 1"
        @close="$emit('close')"
      />

      <MessageAds
        v-if="G_CURRENT_MESSENGER_MESSAGE_SIDEBAR.type == 2"
        :ad-ids="getAdIds"
      />
    </div>
  </b-sidebar>
</template>
<script>
import { mapGetters } from "vuex";
import SidebarHeader from "@/views/commons/Messenger/components/instagram/messages/components/SidebarHeader.vue";
import SearchMessages from "@/views/commons/Messenger/components/instagram/messages/components/SearchMessages.vue";
import MessageAds from "@/views/commons/Messenger/components/messenger/messages/MessageAds.vue";

export default {
  name: "SidebarMessages",
  components: {
    SidebarHeader,
    SearchMessages,
    MessageAds,
  },
  data() {
    return {
      showSidebar: true,
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_MESSENGER_MESSAGE_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_MESSENGER_MESSAGE_SIDEBAR",
      G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR",
    }),
    getAdIds() {
      return this.G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.referral.map(
        (ref) => ref.ad_id
      );
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
#sidebar-rigth-messages {
  .b-sidebar-header {
    padding: 0.5rem 0 !important;
  }
}
</style>