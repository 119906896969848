<template>
  <b-modal
    v-model="showModal"
    @hidden="$emit('hidden')"
    size="xmd"
    :title="titleModal"
    hide-footer
  >
    <div>
      <filter-slot
        :total-rows="totalRowsSearch"
        :paginate="paginateSearch"
        :start-page="startPageSearch"
        :to-page="toPageSearch"
        :filter="[]"
        :filter-principal="filterPrincipal"
        :double-paginate="false"
        @onChangeCurrentPage="searchLeadsMessages()"
        @reload="searchLeadsMessages()"
        :noVisiblePrincipalFilter="true"
      >
        <template #buttons-filter>
          <div class="d-flex align-items-center w-100">
            <b-input-group class="mr-1">
              <b-form-input
                v-if="filterPrincipal.type === 'input'"
                v-model="filterPrincipal.model"
                :type="filterPrincipal.inputType"
                :placeholder="filterPrincipal.placeholder"
                @keyup.enter="searchLeadsMessages()"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchLeadsMessages()">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button variant="primary" v-b-toggle.sidebar-right-leads-search>
              <div class="d-flex justify-content-between">
                <feather-icon icon="FilterIcon" size="15" />
              </div>
            </b-button>
          </div>
        </template>

        <template #table>
          <div class="w-100 d-flex">
            <b-button
              v-if="listSearchLeads.length != 0"
              variant="outline-success"
              @click="saveLeads(1)"
              class="ml-2"
              :disabled="auxListLeads.length == 0"
            >
              <feather-icon icon="SaveIcon" />
              Save ({{ auxListLeads.length }})
            </b-button>

            <b-button
              class="ml-1"
              variant="outline-primary"
              @click="saveLeads(2)"
              v-if="totalRowsSearch > 0"
            >
              <feather-icon icon="SaveIcon" />
              Save All ({{ totalRowsSearch }})</b-button
            >

            <div
              style="
                padding: 5px;
                border-radius: 0.358rem;
                border: 1px solid #8f5fe8 !important;
              "
              class="ml-1 d-flex align-items-center justify-content-center text-info"
              @click="showDetail = true"
            >
              <feather-icon icon="UserIcon" style="margin-right: 3px" />
              Chats Added ({{ G_CURRENT_META_LIST.countLeads }})
            </div>
          </div>

          <div class="px-2 list-border-top sms-topic-list">
            <b-row
              v-if="listSearchLeads.length > 0"
              class="container-leads pb-1"
            >
              <b-col
                class="mt-1"
                lg="3"
                md="4"
                sm="6"
                cols="12"
                v-for="element in listSearchLeads"
                :key="element._id"
              >
                <div
                  class="lead-card-item border"
                  @click="selectElement(element._id)"
                >
                  <div class="w-100">
                    <div class="text-truncate">
                      <b-badge :variant="'light-primary'">{{
                        element.sender.name | limitChars(30)
                      }}</b-badge>
                    </div>
                  </div>
                  <feather-icon
                    icon="CircleIcon"
                    class="icon-selected"
                    size="20"
                    v-if="validateIfExists(element._id)"
                  />
                  <feather-icon
                    icon="CheckIcon"
                    class="text-success"
                    size="20"
                    v-else
                  />
                </div>
              </b-col>
            </b-row>
            <div class="text-center mt-2" v-else>
              There are no records matching your request
            </div>
          </div>
        </template>
      </filter-slot>
    </div>

    <b-sidebar
      id="sidebar-right-leads-search"
      bg-variant="white"
      text-variant="dark"
      right
      width="25%"
      shadow
      title="Advanced Search"
    >
      <div class="mx-2 mt-1">
        <b-row>
          <b-col class="mb-1 text-dark" cols="12"> LM = Last Message </b-col>
          <b-col cols="6">
            <b-form-group label="From (LM): ">
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                v-model="fromDate"
                locale="en"
                placeholder="Date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="To (LM): ">
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                v-model="toDate"
                locale="en"
                placeholder="Date"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- LEADS -->
      <div class="mx-2">
        <b-row>
          <b-col cols="6">
            <b-form-group label="Category: ">
              <v-select
                v-model="category"
                :options="categories"
                label="label"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Classification: ">
              <v-select
                size="sm"
                v-model="classification"
                :options="classifications"
                label="label"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col cols="6">
            <b-form-group label="classification: ">
              <v-select
                size="sm"
                v-model="classification"
                :options="[
                  { value: 1, label: 'YES' },
                  { value: 2, label: 'NO' },
                ]"
                label="label"
              />
            </b-form-group>
          </b-col> -->
        </b-row>
      </div>
      <template #footer>
        <div class="d-flex justify-content-end mb-1 mr-1">
          <div class="d-flex">
            <b-button variant="info" class="mr-1" @click="resetAllFilters"
              >Reset</b-button
            >
            <b-button variant="primary" @click="searchLeadsMessages"
              >Search</b-button
            >
          </div>
        </div>
      </template>
    </b-sidebar>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import MetaLeadsService from "@/views/commons/Messenger/components/lists/services/meta-leads.service.js";

export default {
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_CURRENT_META_LIST: "MessengerMetaStore/G_CURRENT_META_LIST",
    }),

    titleModal() {
      return "SEARCH CHATS";
    },
  },
  data() {
    return {
      showModal: true,
      showDetail: false,
      loadingInfo: false,

      category: null,
      leads: null,
      classification: null,

      listSearchLeads: [],
      auxListLeads: [],

      categories: [
        { label: "New", value: "New" },
        { label: "Lead", value: "Lead" },
      ],
      classifications: [
        { label: "NO REACTION", value: "NO REACTION" },
        { label: "PENDING", value: "PENDING" },
        { label: "POTENTIAL", value: "POTENTIAL" },
      ],

      loadingSearch: true,

      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Name",
        model: "",
      },
      totalRowsSearch: 0,
      paginateSearch: {
        currentPage: 1,
        perPage: 50,
      },
      startPageSearch: 0,
      toPageSearch: 0,

      fromDate: null,
      toDate: null,
    };
  },

  methods: {
    ...mapMutations({
      M_SET_NEW_OR_UPDATE_LIST: "MessengerMetaStore/M_SET_NEW_OR_UPDATE_LIST",
    }),

    async searchLeadsMessages() {
      this.loadingSearch = true;
      this.addPreloader();
      try {
        let params = {
          searchText: this.filterPrincipal.model,
          pageId: this.G_CURRENT_FB_PAGE.id,
          page: this.paginateSearch.currentPage,
          perPage: this.paginateSearch.perPage,
          listId: this.G_CURRENT_META_LIST._id,
          category: this.category,
          classification: this.classification,
        };

        if (this.fromDate) {
          params.from = this.fromDate;
        }

        if (this.toDate) {
          params.to = this.toDate + " : 23:59:59";
        }

        const { data } = await MetaLeadsService.getChatsByPage(params);
        this.startPageSearch = data.from ? data.from : 0;
        this.totalRowsSearch = data.total;
        this.toPageSearch = data.to ? data.to : 0;
        this.listSearchLeads = data.data;
        this.loadingSearch = false;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loadingSearch = false;
        this.removePreloader();
      }
    },

    selectElement(id) {
      if (this.readOnly) return;
      const index = this.auxListLeads.findIndex((lead) => lead.id == id);
      if (index !== -1) {
        this.auxListLeads.splice(index, 1);
      } else {
        this.auxListLeads.push({ id });
      }
    },

    validateIfExists(id) {
      const resp = this.auxListLeads.find((element) => element.id == id);
      return resp == undefined;
    },

    async saveLeads(option) {
      if (option == 1 && this.auxListLeads.length == 0) {
        this.showInfoSwal(
          "There are no Chats",
          "Please select a list of the Chats"
        );
        return;
      }

      if (option == 2) {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
      }

      try {
        this.addPreloader();
        const filters = this.generateFilters();

        let params = {
          searchText: this.filterPrincipal.model,
          pageId: this.G_CURRENT_FB_PAGE.id,
          listId: this.G_CURRENT_META_LIST._id,
          leadIds: this.auxListLeads,
          filters: filters,
          category: this.category,
          classification: this.classification,
        };

        if (this.fromDate) {
          params.from = this.fromDate;
        }

        if (option == 2) {
          params.saveAll = true;
        }

        if (this.toDate) {
          params.to = this.toDate + " : 23:59:59";
        }

        const { data } = await MetaLeadsService.saveLeadsToList(params);
        this.showSuccessSwal("Chats saved successfully");
        this.searchLeadsMessages();
        this.auxListLeads = [];

        this.M_SET_NEW_OR_UPDATE_LIST(data);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    resetAllFilters() {
      this.category = null;
      this.leads = null;
      this.classification = null;
      this.fromDate = null;
      this.toDate = null;
    },

    setFilters() {
      if (
        this.G_CURRENT_META_LIST.filters &&
        this.G_CURRENT_META_LIST.filters.length > 0
      ) {
        this.G_CURRENT_META_LIST.filters.forEach((element) => {
          switch (element.name) {
            case "filterPrincipal.model":
              this.filterPrincipal.model = element.value;
              break;
            case "fromDate":
              this.fromDate = element.value;
              break;
            case "toDate":
              this.toDate = element.value;
              break;
            case "category":
              this.category = element.value;
              break;
            case "classification":
              this.classification = element.value;
              break;
            default:
              break;
          }
        });
      }
    },

    generateFilters() {
      let newArrayFilters = [];

      if (this.filterPrincipal.model) {
        newArrayFilters.push({
          name: "filterPrincipal.model",
          value: this.filterPrincipal.model,
          value_alias: this.filterPrincipal.model,
          label: "Search By",
        });
      }

      if (this.fromDate) {
        newArrayFilters.push({
          name: "fromDate",
          value: this.fromDate,
          value_alias: this.fromDate,
          label: "From Date",
        });
      }

      if (this.toDate) {
        newArrayFilters.push({
          name: "toDate",
          value: this.toDate,
          value_alias: this.toDate,
          label: "To Date",
        });
      }

      if (this.category) {
        newArrayFilters.push({
          name: "category",
          value: this.category,
          value_alias: this.category,
          label: "Category",
        });
      }

      if (this.classification) {
        newArrayFilters.push({
          name: "classification",
          value: this.classification,
          value_alias: this.classification,
          label: "Classification",
        });
      }

      return newArrayFilters;
    },
  },

  async mounted() {
    this.setFilters();
  },
};
</script>
