import { nestApi } from "@/service/axios";

class ListMessagesMetaService {
    prefix = '/meta/lists';

    async getMetaListMessage(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/messages/index`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async createListMessage(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/messages`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async updateListMessage(id, payload) {
        try {
            const data = await nestApi.patch(`${this.prefix}/messages/${id}`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getDetail(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/messages/detail`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

}

export default new ListMessagesMetaService();