<template>
  <div class="add-default-responses">
    <div class="title">{{ title }}</div>
    <p class="description">
      Add a text shortcut to quickly insert this answer. Shortcuts must be at
      least three characters long.
    </p>

    <validation-observer ref="form" #default="{ invalid }">
      <validation-provider rules="required|min:3|max:30" v-slot="{ errors }">
        <b-form-group label="Shortcut method">
          <b-form-input
            v-model="shortcutMethod"
            :state="errors[0] ? false : null"
            trim
            placeholder="Add a shortcut method"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        tag="div"
        class="message-container"
        rules="required|min:1|max:500"
        v-slot="{ errors }"
      >
        <b-form-group label="Message">
          <b-form-textarea
            v-model="message"
            :state="errors[0] ? false : null"
            trim
            rows="8"
            max-rows="10"
            placeholder="Write a message..."
            :maxlength="500"
          />
        </b-form-group>
        <div class="actions-message">
          <div class="message-length">{{ message.length }}/{{ 500 }}</div>
          <div ref="emojiContainer" class="emoji-container">
            <feather-icon
              icon="SmileIcon"
              size="18"
              class="cursor-pointer"
              @click="toggleVisibility()"
            />

            <EmojiSelector
              v-if="showEmojiSelector"
              class="emoji-selector"
              @addEmoji="(character) => addEmoji(character)"
            />
          </div>
        </div>
      </validation-provider>
      <div class="actions">
        <b-button variant="outline-secondary" @click.stop="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click.stop="save()" :disabled="invalid">
          Save
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import PredefinedAnswersMetaService from "@/views/commons/Messenger/components/messenger/predefined-answers/services/predefined-answers.service.js";
import EmojiSelector from "@/views/commons/components/customer-tickets/chat-components/EmojiSelector.vue";

export default {
  name: "AddPredefinedAnswers",
  components: {
    EmojiSelector,
  },
  props: {
    answer: {
      type: Object,
      default: () => ({
        shortcutMethod: "",
        message: "",
      }),
      required: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shortcutMethod: "",
      message: "",
      showEmojiSelector: false,
    };
  },
  computed: {
    title() {
      return this.edit ? "Edit answer" : "Create saved answer";
    },
  },
  mounted() {
    this.shortcutMethod = this.answer?.shortcutMethod || "";
    this.message = this.answer?.message || "";
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    addEmoji(character) {
      this.message += character;
    },
    async save() {
      if (this.edit) {
        await this.update();
      } else {
        await this.create();
      }
    },
    async update() {
      try {
        this.addPreloader();
        const params = {
          shortcutMethod: this.shortcutMethod,
          message: this.message,
        };
        await PredefinedAnswersMetaService.update(this.answer._id, params);
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Answer updated successfully"
        );
        this.cancel();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async create() {
      try {
        this.addPreloader();
        const params = {
          shortcutMethod: this.shortcutMethod,
          message: this.message,
        };
        await PredefinedAnswersMetaService.create(params);
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Answer saved successfully"
        );
        this.cancel();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    toggleVisibility() {
      this.showEmojiSelector = !this.showEmojiSelector;
      if (this.showEmojiSelector) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      const emojiContainer = this.$refs.emojiContainer;
      if (emojiContainer && !emojiContainer.contains(event.target)) {
        this.hide();
      }
    },
    hide() {
      this.showEmojiSelector = false;
      document.removeEventListener("click", this.handleClickOutside);
    },
    clearItems() {
      this.message = "";
      this.shortcutMethod = "";
      this.hide();
    },
  },
  beforeDestroy() {
    this.clearItems();
  },
};
</script>
<style lang="scss">
.add-default-responses {
  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .col-form-label {
    font-weight: bold;
  }
  .message-container {
    position: relative;
    .actions-message {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      bottom: 0.2rem;
      right: 1.2rem;
      .message-length {
        font-size: 0.8rem;
      }
      .emoji-container {
        .emoji-selector {
          position: absolute !important;
          bottom: 1.4rem;
          right: 0;
          z-index: 999;
          box-shadow: 0px 3px 8px 0px #dbdbdb;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 0.5rem;
  }
}

.dark-layout {
  .add-default-responses {
    .message-container {
      .actions-message {
        .emoji-container {
          .emoji-selector {
            box-shadow: 0px 3px 8px 0px #0e0e0e;
          }
        }
      }
    }
  }
}
</style>