<template>
  <div class="search-messages-container">
    <div class="search-continer">
      <b-form-input
        v-model="message"
        id="chat-searchbar-input"
        type="text"
        placeholder="Search Messages ..."
      />
    </div>
    <div
      class="list-messages border-bottom"
      v-for="(message, index) in highlightedMessages"
      :key="index"
      v-b-tooltip.hover="message.message"
      @click="searchMessage(message)"
    >
      <span class="d-flex justify-content-end">
        {{ getMessageDate(message.createdTime) }}
      </span>
      <p class="m-0 text-truncate" v-html="message.highlightedText"></p>
    </div>
    <div v-if="messages.length == 0 && !loading" class="messages-not-found">
      <span>No messages found</span>
    </div>
    <Spinner v-if="loading" class="text-center py-2" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Spinner from "@/views/commons/Messenger/components/messenger/messages/Spinner.vue";
import InstagramMetaService from "@/views/commons/Messenger/components/instagram/services/instagram.meta.service.js";
import moment from "moment";

export default {
  name: "SearchMessages",
  components: {
    Spinner,
  },
  data() {
    return {
      showSidebar: false,
      messages: [],
      loading: false,
      message: "",
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR",
    }),
    highlightedMessages() {
      if (!this.message) {
        return this.messages.map((msg) => ({
          ...msg,
          highlightedText: msg.message,
        }));
      }
      const searchRegex = new RegExp(
        `(${this.escapeRegExp(this.message)})`,
        "gi"
      );
      return this.messages.map((msg) => {
        const highlightedText = msg.message.replace(
          searchRegex,
          '<span class="highlight">$1</span>'
        );
        return { ...msg, highlightedText };
      });
    },
  },
  methods: {
    ...mapActions({
      A_SET_SEARCH_MESSAGE: "MessengerMetaStore/A_SET_SEARCH_MESSAGE",
    }),
    async searchMessages() {
      try {
        this.loading = true;
        const params = {
          searchText: this.message,
          chatId: this.G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR.id,
          npage: 1,
          perpage: 10,
          type: "Chat",
        };
        const { data } = await InstagramMetaService.getInstagramChatMessages(
          params
        );
        this.messages = [...data];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
    searchMessage(message) {
      this.A_SET_SEARCH_MESSAGE({ id: message.id });
      this.$emit("close");
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },
    getMessageDate(messageDate) {
      const date = moment(messageDate);
      const today = moment().startOf("day");
      const yesterday = moment().subtract(1, "days").startOf("day");
      if (date.isSame(today, "day")) {
        return date.format("h:mm a");
      }
      if (date.isSame(yesterday, "day")) {
        return "yesterday";
      }

      return date.format("DD/MM/YY");
    },
  },
  watch: {
    message: function (val) {
      this.messages = [];
      if (val.trim().length < 2) return;
      this.searchMessages();
    },
  },
};
</script>
<style lang="scss">
.search-messages-container {
  width: 100%;
  height: 100%;

  .search-continer {
    margin: 0.5rem 0 2rem 0;
    padding: 0 1rem;
    #chat-searchbar-input {
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="18px" stroke="%23ffffff" height="18px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg>')
        no-repeat;
      padding: 0.5rem 0rem 0.5rem 3rem;
      background-position: 10px;
      border-radius: 10px !important;
      font-size: initial;
    }
  }
  .list-messages {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    cursor: pointer;
    &:hover {
      background-color: #0099dd11;
    }
    .highlight {
      font-weight: bolder;
    }
  }
  .messages-not-found {
    text-align: center;
    margin: 1rem 0;
  }
}

.dark-layout {
  .search-messages-container {
    .list-messages {
      background-color: #1e1e1e;
      .highlight {
        color: #0099dd;
      }
    }
  }
}
</style>
