import { nestApi } from "@/service/axios";

class ListMetaService {
    prefix = '/meta';

    async getMetaList(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/lists/index`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async createList(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/lists`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async updateList(id, payload) {
        try {
            const data = await nestApi.patch(`${this.prefix}/lists/${id}`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

}

export default new ListMetaService();